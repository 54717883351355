import { useEffect, useState } from "react";
import { useUserContext } from "../../../../../../../../../context/userContext";
import { useStudio } from "../../../../../../../../../context/studioContext";
import apiClient from "../../../../../../../../../api/apiClient";
import { formatPhoneNumber } from "./utils/functions";

function useLogicFormAddorUpdateClient({ isOpen, handleSuccessAdd, handleSuccessUpdate, client }) {
    const { userData } = useUserContext();
    const { studio } = useStudio();
    const [nameClient, setNameClient] = useState('Имя');
    const [subnameClient, setSubnameClient] = useState('Фамилия');
    const [number, setNumber] = useState('Номер');
    const [email, setEmail] = useState('Email');
    const [validationMassegeEmail, setValidationMassegeEmail] = useState('Email');
    const [validationMassegeName, setValidationMassegeName] = useState('');
    const [validationMassegeSubname, setValidationMassegeSubname] = useState('');
    const [validationMassegeNumber, setValidationMassegeNumber] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isValidInputs, setIsValidInputs] = useState(false);
    const [isValidMessages, setIsValidMessages] = useState(false);
    const [validMessage, setValidMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    function handleChangeName(e) {
        setNameClient(e);
    }

    function handleChangeSubname(e) {
        setSubnameClient(e);
    }

    function handleChangeNumber(e) {
        let numberFromStr = formatPhoneNumber(e);
        setNumber(numberFromStr);
    }

    function handleChangeEmail(e) {
        setEmail(e);
    }

    async function handleSubmit() {
        try {
            setIsLoading(true);
            let data = {
                name: nameClient,
                surname: subnameClient,
                companyId: userData.company,
            }
            if (email && email !== "") {
                data.email = email;
            }
            if (number && number !== "") {
                data.phoneNumber = '7' + number;
            }

            if (client) {
                data.clientId = client._id;
                const updatedCient = await apiClient.updateClient(data)
                client = updatedCient;
                handleSuccessUpdate(updatedCient);
            } else {
                data.companyBranchId = studio._id;
                const newClient = await apiClient.createClient(data)
                handleSuccessAdd(newClient);
            }

            setIsLoading(false);
            setValidMessage('');
        } catch (err) {
            setIsLoading(false);
            console.log(err);
            setValidMessage(err.message)
        }

    }

    useEffect(() => {
        setNameClient('');
        setSubnameClient('');
        setNumber('');
        setEmail('');
        setValidationMassegeName('Имя');
        setValidationMassegeSubname('Фамилия');
        setValidationMassegeNumber('Номер');
        setValidationMassegeEmail('Email');
    }, [isOpen]);

    useEffect(() => {
        if (nameClient === "" || subnameClient === "") {
            setIsValidInputs(false);
        } else {
            setIsValidInputs(true);
        }
    }, [nameClient, subnameClient, number, email]);

    useEffect(() => {
        if (
            validationMassegeEmail !== 'Email' ||
            validationMassegeName !== 'Имя' ||
            validationMassegeSubname !== 'Фамилия' ||
            validationMassegeNumber !== 'Номер'
        ) {
            setIsValidMessages(false);
        } else {
            setIsValidMessages(true);
        }
    }, [validationMassegeEmail, validationMassegeName, validationMassegeSubname, validationMassegeNumber]);

    useEffect(() => {
        if (isValidInputs && isValidMessages) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [isValidInputs, isValidMessages]);

    //Если редактирование и есть клиент
    useEffect(() => {
        if (client) {
            setNameClient(client.name);
            setSubnameClient(client.surname);
            if (client.contactData.phoneNumber) {
                setNumber(client.contactData.phoneNumber)
            }
            if (client.contactData.email) {
                setEmail(client.contactData.email)
            }
        }
    }, [client])

    return {
        nameClient,
        subnameClient,
        number,
        email,
        isSubmitDisabled,
        handleChangeName,
        handleChangeSubname,
        handleChangeNumber,
        handleChangeEmail,
        handleSubmit,
        isLoading,
        validMessage,
        setValidMessage
    };
}

export default useLogicFormAddorUpdateClient;
