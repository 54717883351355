import React from "react";

function TrainerInfo({ trainer, handleClick }) {
    return (
        <div className='client-info'>
            <p className={`client-info__text font-weight-bold`} onClick={handleClick ? () => handleClick(trainer) : () => { }}>
                {trainer.surname} {trainer.name}
            </p>
        </div>
    )
}

export default TrainerInfo;