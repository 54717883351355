import { useEffect, useState } from "react";

function useLogicModalAddClientOnTraining(isClose, client) {
    const [openedForm, setOpenedForm] = useState('FormChoiceNameOrQR');

    function handleClose() {
        isClose();
        setTimeout(() => setOpenedForm(client ? 'FormRemoveVisit' : 'FormChoiceNameOrQR'),
            1000)
    }

    useEffect(() => {
        if (client) {
            setOpenedForm('FormRemoveVisit')
        } else {
            setOpenedForm('FormChoiceNameOrQR')
        }
    }, [client])
    return ({
        openedForm, setOpenedForm,
        handleClose
    })
}

export default useLogicModalAddClientOnTraining;