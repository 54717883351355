import { useState } from "react"

function useLogicNonMembershipVisitsModalAndForms({ handleSuccessConfirm, handleRemove, closeModal }) {
    const [form, setForm] = useState('FormChoiceConfirmOrRemove')

    function closeForms() {
        closeModal()
        setTimeout(() => setForm('FormChoiceConfirmOrRemove'), 1000)
    }

    function handleSuccess(state) {
        if (state !== 'remove') {
            handleSuccessConfirm();
        }else{
            handleRemove();
        }
        closeForms()
    }

    return ({
        handleSuccess,
        form, setForm,
        closeForms,
    })
}

export default useLogicNonMembershipVisitsModalAndForms;