import React from "react";

/** Статистика по оплате тренеру */
function TrainerPayment({ trainingSummary }) {
    
    return (
        <div className="trainer-statistics__container-columns">
            <div className="trainer-statistics__container-columns__types">
                <p className="trainer-statistics__text">Типы</p>
                {trainingSummary &&
                    trainingSummary.map((tr) => {
                        return <p key={tr.type} className="trainer-statistics__text">{tr.type} </p>
                    })}
            </div>
            <div className="trainer-statistics__container-columns__typesOfPayment">
                <p className="trainer-statistics__text">Оплата</p>
                {trainingSummary &&
                    trainingSummary.map((tr) => {
                        const array = { client: 'за посещение', training: 'за тренировку' }
                        return <p key={array[tr.typePayment]} className="trainer-statistics__text">{array[tr.typePayment]}</p>
                    })}
            </div>
            <div className="trainer-statistics__container-columns__quantity">
                <p className="trainer-statistics__text">₽</p>
                {trainingSummary &&
                    trainingSummary.map((tr) => {
                        const sum = Number(tr.payment) * Number(tr.visits)
                        return <p key={sum.toString()} className="trainer-statistics__text">{sum}</p>
                    })}
            </div>
        </div>
    )
}

export default TrainerPayment;