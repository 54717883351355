import React from "react";
import FormAdd from "../../../../../../commonComponents/forms/FormAdd/FormAdd";
import FormPaymentSetting from "../../../../../ClientsAndEmployees/components/List/TrainersList/components/forms/FormAddTrainer/components/FormPaymentSetting/FormPaymentSetting";
import useLogicFormUpdateTranerPaymentSetting from "./logic/useLogicFormUpdateTranerPaymentSetting";

/** Форма редактирования оплаты тренера */
function FormUpdateTranerPaymentSetting({ paymentSettings, handleSuccessUpdate }) {

    const {
        update,
        isValidForm, setIsValidForm,
        loading,
        validFormText,
        fillPaymentSetting,
        clearTextValidation,
    } = useLogicFormUpdateTranerPaymentSetting({ handleSuccessUpdate });

    return (
        <FormAdd
            text={'Редактировать'}
            onSubmit={update}
            validationOptions={!isValidForm}
            textButtonSubmit={'Редактировать'}
            isLoading={loading}
            textForFill={validFormText}
            cleanTextForFill={clearTextValidation}
            children={
                <FormPaymentSetting
                    handleDataPayment={fillPaymentSetting}
                    handleFormValidate={setIsValidForm}
                    paymentSettings={paymentSettings}
                />
            }
        />
    )
}

export default FormUpdateTranerPaymentSetting;