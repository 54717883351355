import { useStudio } from "../../context/studioContext";


function ModalWithChildren({
    isOpen,
    styleAllForm, // стиль всей формы
    isClose,
    children,
}) {

    const { objStyles } = useStudio();

    return (
        <div className={`formadd ${styleAllForm} formadd_overlay ${isOpen ? "formadd_show" : ""}`}>
            <div className={`formadd__content ${objStyles.backgroundColor} ${styleAllForm}`}>
                <button className={`formadd__close-button `} type="button" onClick={isClose} />
                {children}
            </div>
        </div>
    )
}

export default ModalWithChildren;