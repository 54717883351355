import { useState } from 'react';

import imgPhoneSearch from '../../../assets/images/search-svgrepo-com.svg' // картинка кнопки поиска по телефону
import imgNameSurnameSearch from '../../../assets/images/mobile-svgrepo-com.svg' // картинка кнопки поиска по телефону
import imgPhoneSearchDark from '../../../assets/images/Dark/mobile-svgrepo-com.svg' // картинка кнопки поиска по телефону
import imgNameSurnameSearchDark from '../../../assets/images/Dark/search-svgrepo-com.svg' // картинка кнопки поиска по телефону
import { useStudio } from '../../../context/studioContext';

/**
 * Компонент формы поиска
 * @param {Object} props - Свойства компонента
 * @param {Function} props.handleSubmOnSearch - Функция обработки сабмита формы
 * @param {string} props.styleSearchForm - Стиль формы поиска
 * @param {string} props.styleTextSearchForm - Стиль текста
 * @returns {JSX.Element} Компонент формы поиска
 */
function SearchForm({
    handleSubmOnSearch, // сабмит 
    styleSearchForm, // стиль формы поиска
    styleTextSearchForm, // стиль текста
}) {

    const { studio } = useStudio();

    const [searchValue, setSearchValue] = useState(""); // добавляем состояние и функцию для его обновления

    function handleChangeValueNameAndSurname(event) { // обновляем состояние при изменении значения инпута
        setSearchValue(event.target.value)
    }

    const submit = (e, opt) => {
        e.preventDefault();
        const data = {
            options: opt,
            searchValue,
        }
        handleSubmOnSearch(searchValue)
    };

    return (

        <form className="search-form" onSubmit={(e) => submit(e, 'name')}>
            <div className={`search-form__container ${styleSearchForm}`}>
                <div className="search-form__container-input">
                    {/* <button
                        className="search-form__input-button search-form__input-button_on-phone"
                        onClick={(e) => submit(e, 'name')}
                    >
                        {imgPhoneSearch &&
                            <img className="search-form__img" src={studio === 'flex' ? imgPhoneSearch : imgPhoneSearchDark} alt="изибражение телефона" />}
                    </button> */}
                    <input className={`search-form__input ${styleTextSearchForm}`} type="text" value={searchValue} name="name" placeholder="Поиск" onChange={handleChangeValueNameAndSurname}></input>
                    <button
                        className={`search-form__input-button`}
                        onClick={(e) => submit(e, 'phone')}
                    >
                        <img className="search-form__img" src={studio === 'flex' ? imgNameSurnameSearch : imgNameSurnameSearchDark} alt="изображение стрелки" />
                    </button>
                </div>
            </div>
        </form >
    )
}

export default SearchForm;