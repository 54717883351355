import React from "react";

/** Информация о админе */
function AdminInfo({ admin, onClick }) {

    return (
        <div className="trainer-info" onClick={onClick}>
            <div className="trainer-info__container-name">
                <p className="trainer-info__text trainer-info__text_name">{admin && admin.name}</p>
                <p className="trainer-info__text">{admin && admin.surname}</p>
            </div>
            {admin && admin.contactData && admin.contactData.email &&
                <p className="trainer-info__text">{admin.contactData.email}</p>
            }
            {admin && admin.contactData && admin.contactData.phoneNumber &&
                <p className="trainer-info__text">{admin.contactData.phoneNumber}</p>
            }
        </div>
    )
}

export default AdminInfo;