import { useState } from "react";
import apiTrainer from "../../../../../../../api/apiTrainer";
import { useStudio } from "../../../../../../../context/studioContext";
import { useNavigate } from "react-router-dom";

function useFormRemoveTrainerFromBranch({ trainer, closeForm }) {
    const { studio } = useStudio();
    const [isLoading, setIsoading] = useState(false);
    const [validateMessage, setValidateMessage] = useState()

    const navigate = useNavigate();

    function navigateTrainersPage(e) {
        //e.preventDefault();
        navigate('/');
    }

    async function removeTrainerFromBranch() {
        try {
            setIsoading(true);
            await apiTrainer.controlTrainerCompanyBranch({
                companyBranchId: studio._id,
                trainerId: trainer._id,
                action: 'pull',
            })
            setIsoading(false);
            closeForm();
            navigateTrainersPage();
        }
        catch (err) {
            setIsoading(false);
            setValidateMessage(err.message);
        }
    }

    return ({
        removeTrainerFromBranch,
        isLoading,
        validateMessage, setValidateMessage,
    })
}

export default useFormRemoveTrainerFromBranch;