import React, { createContext, useContext, useEffect, useState, } from 'react';
import { getCompanyBranchesAsyncLocalStorage, getStudioValueAsyncLocalStorage, saveCompanyBranchesAsyncLocalStorage } from '../utils/localStorage/studioChoice';
import apiEmployee from '../api/apiEmployee';
import { useUserContext } from './userContext';

const StudioContext = createContext();

export const StudioContextProvider = ({ children }) => {
    const { userData } = useUserContext();
    const [studio, setStudio] = useState(undefined);
    const [companyBranches, setCompanyBranches] = useState(undefined);
    const [studioLoading, setStudioLoading] = useState(false);
    const [objStyles, setObjStyles] = useState({
        backgroundColor: 'background-color__flex',
        colorText: 'text-color-flex',
    })

    function controlTextColorBytheme(theme) {
        // Выбираем все элементы <p> и <h>
        const paragraphs = document.querySelectorAll('p');
        const headers = document.querySelectorAll('h1, h2, h3, h4, h5, h6');

        // Удаляем все классы, начинающиеся на "text-color"
        paragraphs.forEach(p => {
            p.classList.forEach(className => {
                if (className.startsWith('text-color')) {
                    p.classList.remove(className);
                }
            });
        });

        headers.forEach(h => {
            h.classList.forEach(className => {
                if (className.startsWith('text-color')) {
                    h.classList.remove(className);
                }
            });
        });

        // Добавляем класс в зависимости от темы
        const classNameText = theme.includes('Flex') ? 'text-color-flex' : 'text-color-dark';
        // Добавляем класс к элементам <p>
        paragraphs.forEach(p => {
            p.classList.add(classNameText);
        });
        // Добавляем класс к элементам <h1> - <h6>
        headers.forEach(h => {
            h.classList.add(classNameText);
        });
    }

    function updateTheme(theme) {
        const mainElement = document.querySelector('.App');

        // Удаляем все классы, начинающиеся на "app__"
        mainElement.classList.forEach(className => {
            if (className.startsWith('app__')) {
                mainElement.classList.remove(className);
            }
        });
        // Добавляем класс в зависимости от темы
        if (theme.includes('Flex')) {
            mainElement.classList.add('app__flex');
            objStyles.backgroundColor = 'background-color__flex'
            objStyles.colorText = 'text-color-flex'
        } else if (theme.includes('DARK')) {
            mainElement.classList.add('app__dark');
            objStyles.backgroundColor = 'background-color__dark'
            objStyles.colorText = 'text-color-dark'
        }
        controlTextColorBytheme(theme);
    }

    useEffect(() => {
        if (studio) {
            updateTheme(studio.name)
        }
    }, [studio]);

    async function fill() {
        try {
            setStudioLoading(true);
            const nameStudio = await getStudioValueAsyncLocalStorage();
            if (!nameStudio) {
                if (userData.companyBranches) {
                    //await saveCompanyBranchesAsyncLocalStorage(userData.companyBranches)
                }
            }
            // const branches = getCompanyBranchesAsyncLocalStorage();
            // setCompanyBranches(branches);
            setStudio(nameStudio);
            setStudioLoading(false);
        }
        catch (err) {
            console.log(err);
            setStudioLoading(false);
        }
    }

    useEffect(() => {
        if (userData) {
            fill()
        }
    }, [userData])

    return (
        <StudioContext.Provider value={{
            studio,
            setStudio,
            studioLoading,
            companyBranches,
            setCompanyBranches,
            objStyles,
        }}>
            {children}
        </StudioContext.Provider>
    )

}


export const useStudio = () => {
    return useContext(StudioContext);
};