import { useEffect, useState } from "react";
import { useStudio } from "../../../../../context/studioContext";
import apiTraining from "../../../../../api/apiTraining";
import { calculateUniqueCombinations } from "./utils";

function useLogicTrainerStatistics({ dateOn, dateTo, trainerId }) {
    const { studio } = useStudio();
    const [trainings, setTrainings] = useState([]);
    const [trainingSummary, setTrainingSummary] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [validateMessage, setValidateMessage] = useState();

    async function getTrainings() {
        try {
            setIsLoading(true);

            const data = await apiTraining.getTrainingsInRangeByTrainer({
                trainerId,
                dateOn,
                dateTo,
                companyBranchId: studio._id
            })
            setTrainings(data.trainings);
            const values = calculateUniqueCombinations(data.trainings, data.paymentSettigs)
            setTrainingSummary(values)
            setIsLoading(false);
        }
        catch (err) {
            setIsLoading(false);
            setValidateMessage(err.message)
        }
    }

    useEffect(() => {
        if (dateOn && dateTo && trainerId) {
            getTrainings()
        }
    }, [dateOn, dateTo, trainerId])

    return ({
        validateMessage,
        isLoading,
        trainings,
        trainingSummary,
    })
}

export default useLogicTrainerStatistics;
