import React from "react";
import Preloader from "../../../../../../../../../commonComponents/loaders/Preloader/Preloader"
import useLogicFormAddCurrentAdmin from "./logic/useLogicFormAddCurrentAdmin";
import AdminInfo from "../../../AdminInfo/AdminInfo";
import FormTwoTextButton from "../../../../../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton";

/** Форма добавления тренера  филиалу из списка существующих тренеров */
function FormAddCurrentAdmin({ handleSuccessAdd }) {

    const {
        admins,
        validMessage,
        isLoaing,
        selectedAdmin,
        selectAdmin,
        addAdmin,
        openedForm, setOpenedForm,
        isLoadingAdmin,
    } = useLogicFormAddCurrentAdmin({handleSuccessAdd});

    return (
        !isLoaing ?
            <>
                {validMessage &&
                    <p>{validMessage}</p>
                }
                {openedForm !== 'FormConfirm' && !validMessage &&
                    < div className="list margin-top__30px">
                        {admins ?
                            admins.map(admin => {
                        
                                return (
                                    <button
                                        className={`list__element`}
                                        id={admin._id}
                                        key={admin._id}
                                        onClick={(e) => selectAdmin(admin)} // клик на клиента в списке
                                    >
                                        <AdminInfo admin={admin} />
                                    </button>)
                            })
                            : <></>
                        }
                    </div>
                }
                {openedForm === 'FormConfirm' && !validMessage &&
                    <FormTwoTextButton
                        title={`Добавить ${selectedAdmin?.name} ${selectedAdmin?.surname}`}
                        nameButtonOne={'Да'}
                        nameButtonTwo={'Нет'}
                        clickButtonOne={addAdmin}
                        clickButtonTwo={() => setOpenedForm()}
                        isLoading={isLoadingAdmin}
                    />}
            </>
            : <Preloader />
    )
}

export default FormAddCurrentAdmin;