import { useEffect, useState } from "react";
import InputText from "../../commonComponents/inputs/InputText/InputText";
import ButtonWithBorder from "../buttons/ButtonWithBorder/ButtonWithBorder";
import { regexEmail, regexPassword, regexStrokeInput } from "../../utils/regex";
import apiEmployee from "../../api/apiEmployee";
import { useUserContext } from "../../context/userContext";
import { saveCompanyBranchesAsyncLocalStorage } from "../../utils/localStorage/studioChoice";

function Login() {
    
    const { refreshUserAuth } = useUserContext();
    // Стейты, в которых содержятся значения инпута
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [validationMassegePassword, setValidationMassegePassword] = useState();
    const [validationMassegeLogin, setValidationMassegeLogin] = useState();
    const [validationText, setValidationText] = useState('');
    const [validationAll, setValidationAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    async function submit() {
        try {
            setIsLoading(true);
            const res = await apiEmployee.signinEmployee(login, password);
            await saveCompanyBranchesAsyncLocalStorage(res.companyBranches);
            setIsLoading(false);
            setValidationText('');
            refreshUserAuth();
        } catch (err) {
            console.log(err);
            setValidationText(err.message)
            setIsLoading(false);
        }
    }

    // Валидация
    useEffect(() => {

        const validLogin = login !== '' && regexStrokeInput.test(login) ? true : false;
        const validPassword = password !== '' && regexPassword.test(password) ? true : false;

        if (validLogin && validPassword) {
            setValidationAll(true);
            setValidationMassegeLogin('');
            setValidationMassegePassword('');
        } else {
            setValidationAll(false);
            !regexEmail.test(login) ? setValidationMassegeLogin('') : setValidationMassegeLogin('');
            !regexPassword.test(password) ? setValidationMassegePassword('') : setValidationMassegePassword('');
        }

    }, [login, password])


    return (
        <div className="login">
            <h2 className="login__title">{ }</h2>
            <InputText
                label={'Логин'}
                inputValue={login}
                setInputChange={setLogin}
                placeholder={'Введите логин'}
                validationText={validationMassegeLogin}
            />
            <InputText
                inputValue={password}
                setInputChange={setPassword}
                placeholder={'Введите пароль'}
                validationText={validationMassegePassword}
                label={'Пароль'}
            />
            <div className="login__button">
                <ButtonWithBorder isLoading={isLoading} text={'Войти'} onClick={submit} isDisabled={!validationAll} />
            </div>
            <p className="login__validation-text">{validationText}</p>
            {/* <div className="login__container-text-password-recover">
                <p className="login__container-text-password-recover-text">{'Войти'}</p>
                <button className="login__container-text-password-recover-link">{'Войти'}</button>
            </div> */}
        </div>
    )
}

export default Login;