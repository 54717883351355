import React from 'react';
import Selector from '../../../../commonComponents/selectors/Selector/Selector';

/** Переключатель опций поиска */
function Switcher({ select, handleSelect }) {

    return (
        <div className='switcher-super-admin'>
            {/* Селектор тренера: только для отображения, меняется в зависимости от селектора клиентов и зависит от него */}
            < Selector
                nameOne={'Тренеры'}
                handleOnClickOne={() => handleSelect('trainers')}
                switchControl={select === 'trainers'} // внешний контроль отображения, true -вкл
                onlyOn={true} // true - не возвращать селектор при повторном клике, можно только включать
                styleSelector={'formadd__checkbox_super-admin'}
            />
            {/* Селектор клиентов */}
            < Selector
                nameOne={'Клиенты'}
                handleOnClickOne={() => handleSelect('clients')}
                switchControl={select === 'clients'} // внешний контроль отображения, true -вкл
                onlyOn={true} // true - не возвращать селектор при повторном клике, можно только включать
                styleSelector={'formadd__checkbox_super-admin'}
            />
            {/* Селектор админов */}
            < Selector
                nameOne={'Админы'}
                handleOnClickOne={() => handleSelect('admins')}
                switchControl={select === 'admins'} // внешний контроль отображения, true -вкл
                onlyOn={true} // true - не возвращать селектор при повторном клике, можно только включать
                styleSelector={'formadd__checkbox_super-admin'}
            />
        </div>
    )
}

export default Switcher;