import { useUserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import ButtonImg from "../buttons/ButtonImg/ButtonImg";
import imgPeople from "../../assets/images/group-svgrepo-com.svg";
import imgNonMemberShipVisit from "../../assets/images/question-answer-help-svgrepo-com.svg"
import imgTimeTable from "../../assets/images/calendar-1-svgrepo-com.svg";
import imgPersonal from "../../assets/images/personal.svg"
import imgSetting from "../../assets/images/setting-svgrepo-com.svg"

function NavTab({ childComponent }) {

    const { userData } = useUserContext();
    const navigate = useNavigate();

    function onClickList(e) {
        e.preventDefault();
        navigate('/');
    }

    function onClickTimeTable(e) {
        e.preventDefault();
        navigate('/time-table');
    }

    function onClickSetting(e) {
        e.preventDefault();
        navigate('/setting');
    }

    function onClickNonMemberShipVisit(e) {
        e.preventDefault();
        navigate('/nonmembershipvisit');
    }

    function onClickPersonal(e) {
        e.preventDefault();
        if (userData.role === 'trainer') {
            navigate('/trainer');
        }
    }

    return (
        <div className="nav-tab">
            {['superAdmin', 'admin'].includes(userData.role) &&
                <ButtonImg img={imgPeople} handleClick={onClickList} />}
            {['superAdmin', 'admin'].includes(userData.role) &&
                <ButtonImg img={imgNonMemberShipVisit} handleClick={onClickNonMemberShipVisit} />}
            {['trainer'].includes(userData.role) &&
                <ButtonImg img={imgPersonal} handleClick={onClickPersonal} customStyle={'nav-tab__img-personal'} />}
            {['superAdmin', 'admin'].includes(userData.role) &&
                <ButtonImg img={imgSetting} handleClick={onClickSetting} />}
            <ButtonImg img={imgTimeTable} handleClick={onClickTimeTable} />
        </div>
    )
}

export default NavTab;