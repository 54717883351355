import FormTwoTextButton from "../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton"

const FormChoiceConfirmOrRemove = ({
    openFormConfirmVisit,
    opeFormremoveVisit,
}) => {
    return (
        <FormTwoTextButton
            title={'Подтвердить или удалить визит?'}
            nameButtonOne={'Подтвердить'}
            nameButtonTwo={'Удалить'}
            clickButtonOne={openFormConfirmVisit}
            clickButtonTwo={opeFormremoveVisit}
        />

    )
}

export default FormChoiceConfirmOrRemove;