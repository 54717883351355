import './App.css';
import MainPage from './Pages/MainPage/MainPage';
import MainRoutes from './Routes/MainRoutes';
import { StudioContextProvider } from './context/studioContext';
import { UserContextProvider } from './context/userContext';
// localStorage.clear();

function App() {

  return (
    <main className="App">
      <UserContextProvider>
        <StudioContextProvider>
          <MainPage />
        </StudioContextProvider>
      </UserContextProvider>
    </main>
  );
}

export default App;
