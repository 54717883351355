import React from "react";
import ModalWithChildren from "../../../../../../../commonComponents/modal/ModalWithChildren";
import FormAddorUpdateAdmins from "./forms/FormAddorUpdateAdmin/FormAddorUpdateAdmin";
import useModalWithForm from "./logic/useModalWithForm";
import FormChoice from "./forms/FormChoice/FormChoice";
import FormAddCurrentAdmin from "./forms/FormAddCurrentAdmin/FormAddCurrentAdmin";

function ModalWithForm({
    isOpen,
    isClose,
    handleSuccessAdd,
}) {

    const {
        openedForm, setOpenedForm,
        closeForm,
    } = useModalWithForm({ isClose });

    return (
        <ModalWithChildren
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={closeForm} // клик на кнопку для сворачивания
            children={
                <>
                    {openedForm === 'FormChoice' &&
                        <FormChoice
                            addCurrent={() => setOpenedForm('FormAddCurrentAdmin')}
                            addNew={() => setOpenedForm('FormAddorUpdateAdmins')}
                        />}
                    {openedForm === 'FormAddorUpdateAdmins' &&
                        <FormAddorUpdateAdmins
                            textButtonSubmit={'Создать'}
                            handleSuccessAdd={handleSuccessAdd}
                        />
                    }
                    {openedForm === 'FormAddCurrentAdmin' &&
                        <FormAddCurrentAdmin
                            handleSuccessAdd={handleSuccessAdd}
                        />
                    }
                </>
            }
        />
    )
}

export default ModalWithForm;