import { useEffect, useState } from "react";
import { useStudio } from "../../../context/studioContext";

/** Компонент выбора студии */
function LoaderNames() {
    const { studio } = useStudio();
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsAnimated(prev => !prev);
        }, 1000);

        return () => clearInterval(interval);
    }, []);
    // console.log(studio.name === 'Flex Lab')
    return (
        <div className={`loader-names ${isAnimated ? 'loader-names__animated-opacity' : ''}`}>
            {studio ?
                <>
                    {studio?.name === 'Flex Lab' &&
                        <>
                            <p
                                className={`loader-names__link-page loader-names__link-page_white`}
                                onClick={() => { }}>
                                {'Flex Lab'}
                            </p>
                            {/* <div className={`loader-names__separator loader-names__separator_white`}></div> */}
                        </>
                    }
                    {studio?.name === 'DARK' &&
                        <p
                            className={`loader-names__link-page loader-names__link-page_Dark `}
                            onClick={() => { }}>
                            {'DARK'}
                        </p>}

                </>
                :
                <>
                    <>
                        <p
                            className={`loader-names__link-page loader-names__link-page_white`}
                            onClick={() => { }}>
                            {'Flex Lab'}
                        </p>
                        <div className={`loader-names__separator loader-names__separator_white`}></div>

                    </>

                    <p
                        className={`loader-names__link-page loader-names__link-page_Dark `}
                        onClick={() => { }}>
                        {'DARK'}
                    </p>

                </>}

            {/* return (
            <div className="loader-names__container" key={index}>
                <>

                    <button
                        className={`loader-names__link-page
                        ${whiteFlex && branch.name.includes('Flex') ? 'loader-names__link-page_white' : ''}
                        ${branch.name.includes('DARK') ? 'loader-names__link-page_Dark' : ''}`}
                        onClick={() => choiceStudioName(branch.name)}>
                        {branch.name}
                    </button>
                    {index < companyBranches.length - 1 &&
                        <div className={`loader-names__separator ${whiteFlex ? 'loader-names__separator_white' : ''}`}></div>}
                </>
            </div > */}

        </div >
    )
}

export default LoaderNames;