import FormTwoTextButton from '../../../../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton'

/** Форма выбора редактирвоания или удаления настроек абоемента */
const ChoiceForm = ({ name, clickUpdate, clickRemove }) => {
    return (
        <FormTwoTextButton
            title={name}
            nameButtonOne={'Редактировать'}
            nameButtonTwo={'Удалить'}
            clickButtonOne={clickUpdate}
            clickButtonTwo={clickRemove}
        />
    )
}

export default ChoiceForm;