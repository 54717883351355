import React from "react";
import InputText from "../../../../../../../commonComponents/inputs/InputText/InputText";
import InputSelect from "../../../../../../../commonComponents/inputs/InputSelect/InputSelect";
import InputDateTimePicker from "../../../../../../../commonComponents/inputs/InputDateTimePicker/InputDateTimePicker";
import FormAdd from '../../../../../../../commonComponents/forms/FormAdd/FormAdd';
import useLogicFormAddOrUpdateTraining from "./logic/useLogicFormAddOrUpdateTraining";
import { trainingTypes } from "../../../../../utils/constants";

function FormAddOrUpdateTraining({
    trainers,
    initDateOn,
    initDateTo,
    selectedHall,
    handleSuccesCreateTraining,
    handleSuccesUpdateTraining,
    updatingTraining, // если передана тренировка - то автоматически реактирвоание
    trainingSettings,
}) {

    const {
        nameTrain, setNameTrain,
        trainer, setTrainer,
        typeTrain, setTypeTrain,
        spaceId, setSpaceId, optionsSpaces,
        numberOfPeople, setNumberOfPeople,
        repeatValue, setRepeatValue, optionsRepeats,
        dateOn, setDateOn,
        dateTo, setDateTo,
        optionsTrainingTypes,
        validMessage, setValidMessage,
        isValidForm,
        isLoading,
        onSubmit,
    } = useLogicFormAddOrUpdateTraining({
        selectedHall,
        handleSuccesCreateTraining,
        handleSuccesUpdateTraining,
        updatingTraining,
        trainingSettings
    });

    return (
        <FormAdd
            onSubmit={onSubmit}
            text={!updatingTraining ? 'Новая Тренировка' : 'Редактировать'}
            textButtonSubmit={!updatingTraining ? 'Создать' : 'Редактировать'}
            validationOptions={!isValidForm} // дисейблить сабмит если true
            textForFill={validMessage}
            cleanTextForFill={() => setValidMessage()}
            isLoading={isLoading}
            buttonUnderText={validMessage}
            children={
                <>
                    <InputText
                        inputValue={nameTrain}
                        setInputChange={setNameTrain}
                        label={'Название'}
                        placeholder='Введите текст'
                    />
                    <InputSelect
                        label={'Тренер:'}
                        placeholder={'Выберите тренера'}
                        options={trainers}
                        value={trainer}
                        onChange={setTrainer}
                    />
                    <InputSelect
                        label={'Тип:'}
                        placeholder={'Тип тренировки'}
                        options={optionsTrainingTypes}
                        value={typeTrain}
                        onChange={setTypeTrain}
                    />
                    <InputSelect
                        label={'Зал:'}
                        placeholder={'Выберите Зал'}
                        options={optionsSpaces}
                        value={spaceId}
                        onChange={setSpaceId}
                    />
                    <InputText
                        inputValue={numberOfPeople}
                        setInputChange={setNumberOfPeople}
                        label={'Вместимость'}
                        placeholder='Введите текст'
                    />
                    <InputSelect
                        label={'Повторы:'}
                        placeholder={'Выберите повторы'}
                        options={optionsRepeats}
                        value={repeatValue}
                        onChange={setRepeatValue}
                    />
                    <InputDateTimePicker
                        handleSetDateTime={setDateOn}
                        label={'Начало:'}
                        initDate={initDateOn}
                    />
                    <InputDateTimePicker
                        handleSetDateTime={setDateTo}
                        label={'До:'}
                        initDate={initDateTo}
                    />
                </>
            }
        />
    )
}

export default FormAddOrUpdateTraining;