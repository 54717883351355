import React from "react";
import ModalWithChildren from "../../../../../commonComponents/modal/ModalWithChildren";
import FormChoiceTrainerActions from "../forms/FormChoice/FormChoiceTrainerActions";
import FormAddorUpdateTrainers from "../../../../../Pages/ClientsAndEmployees/components/List/TrainersList/components/forms/FormAddTrainer/components/FormAddorUpdateTrainer/FormAddorUpdateTrainer"
import useLogicModalTrainerForms from "./logic/useLogicModalTrainerForms";
import FormRemoveTrainerFromBranch from "../forms/FormRemoveTrainerFromBranch/FormRemoveTrainerFromBranch";
import FormUpdateTranerPaymentSetting from "../forms/FormUpdateTranerPaymentSetting/FormUpdateTranerPaymentSetting";

function ModalTrainerForms({ isOpen, closeForm, handleSuccessUpdate, trainer }) {

    const {
        openedForm, setOpenedForm,
        clickCloseForm,
    } = useLogicModalTrainerForms({ closeForm });

    return (
        <ModalWithChildren
            isOpen={['formTrainer', 'FormUpdateTranerPaymentSetting'].includes(isOpen)}
            isClose={clickCloseForm}
            children={
                <>
                    {openedForm === 'FormChoiceTrainerActions' && isOpen !== 'FormUpdateTranerPaymentSetting' &&
                        < FormChoiceTrainerActions
                            openUpdateForm={() => setOpenedForm('FormAddorUpdateTrainers')}
                            openRemoveForm={() => setOpenedForm('FormRemoveTrainerFromBranch')}
                        />
                    }
                    {openedForm === 'FormAddorUpdateTrainers' &&
                        <FormAddorUpdateTrainers
                            trainer={trainer}
                            textButtonSubmit={'Редактировать'}
                            handleSuccessUpdate={handleSuccessUpdate}
                        />}
                    {openedForm === 'FormRemoveTrainerFromBranch' &&
                        <FormRemoveTrainerFromBranch
                            trainer={trainer}
                            closeForm={clickCloseForm}
                        />
                    }
                    {isOpen === 'FormUpdateTranerPaymentSetting' &&
                        <FormUpdateTranerPaymentSetting
                            paymentSettings={trainer.paymenSettings}
                            handleSuccessUpdate={handleSuccessUpdate}
                            closeForm={clickCloseForm}
                        />
                    }
                </>
            }
        />
    )
}

export default ModalTrainerForms;