import React from "react";

/**
 * Компонент для отображения поля ввода даты.
 * @param {Object} props - Свойства компонента.
 * @param {boolean} [props.isRequired=true] - Обязательное ли поле для заполнения.
 * @param {string} props.value - Значение поля ввода даты.
 * @param {function} props.onChange - Обработчик события изменения значения поля ввода.
 * @param {string} props.bottomText - Текст, отображаемый под полем ввода в случае ошибки.
 * @returns {JSX.Element} Компонент InputDate.
 */
function InputDate({
    isRequired = true,
    value,
    onChange,
    bottomText,
    label,
    type = 'datetime-local',
}) {

    function handleInputChange(e) {
        onChange(e.target.value);
    }

    return (
        <>
            <div className="input-date__container">
                {label && <p className="input__label">{label}:</p>}
                <input
                    value={value}
                    type={type}
                    placeholder="Дата"
                    className='input-date'
                    name="date"
                    id={new Date().toString()}
                    minLength="2"
                    maxLength="30"
                    onChange={handleInputChange}
                    required={isRequired}
                />
            </div>
            <span className="input__text-error" id="error-dateto">{bottomText}</span>
        </>
    )
}

export default InputDate;