import { Route, Routes } from "react-router-dom";
import ChoiserStudioPage from "../Pages/ChoiserStudioPage/ChoiserStudioPage";
import NotFoundPage from "../Pages/NotFoundPage/NotFoundPage";
import ClientsAndEmployees from "../Pages/ClientsAndEmployees/ClientsAndEmployees";
import Login from "../commonComponents/Login/Login";
import ClientPage from "../Pages/ClientPage/ClientPage";
import TimeTablePage from "../Pages/TimeTablePage/TimeTablePage";
import ClientListOnTrainingPage from "../Pages/ClientListOnTrainingPage/ClientListOnTrainingPage";
import TrainerPage from "../Pages/TrainerPage/TrainerPage";
import TrainingSettingPage from "../Pages/TrainingSettingPage/TrainingSettingPage";
import SettingPage from "../Pages/SettingPage/SettingPage";
import AbonementSettingPage from "../Pages/AbonementSettingPage/AbonementSettingPage";
import AdminPage from "../Pages/AdminPage/AdminPage";
import NonMembershipVisitPage from "../Pages/NonMembershipVisit/NonMembershipVisitPage";

/** Точка входа всех роутов */
function MainRoutes() {
   
    return (
        <Routes>
            <Route path="/" element={<ClientsAndEmployees />} />
            <Route path="/login" element={<Login />} />
            <Route path="/studio" element={<ChoiserStudioPage />} />
            <Route path="/client" element={<ClientPage />} />
            <Route path="/time-table" element={<TimeTablePage />} />
            <Route path="/training-page" element={<ClientListOnTrainingPage />} /> 
            <Route path="/admin" element={<AdminPage />} />  
            <Route path="/trainer" element={<TrainerPage />} />  
            <Route path="/setting" element={<SettingPage />} />
            <Route path="/training-setting" element={<TrainingSettingPage />} />
            <Route path="/abonement-setting" element={<AbonementSettingPage />} />
            <Route path="/nonmembershipvisit" element={<NonMembershipVisitPage />} />
            <Route path="/*" element={<NotFoundPage />} />
        </Routes>
    )
}

export default MainRoutes;