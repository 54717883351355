import FormTwoTextButton from "../../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton";
import useLogicFormRefreshPassword from "./logic/useLogicFormRefreshPassword";

/** Форма восстановления пароля */
function FormRefreshPassword({
    client,
    closeForm,
}) {

    const {
        loading,
        validateMessage, clearValidateMessage,
        refreshPassword,

    } = useLogicFormRefreshPassword({client});

    return (
        <FormTwoTextButton
            title={'Отправить новый пароль'}
            nameButtonOne={'Да'}
            nameButtonTwo={'Нет'}
            clickButtonOne={refreshPassword}
            clickButtonTwo={closeForm}
            isLoading={loading}
            textForFill={validateMessage}
            cleanTextForFill={clearValidateMessage}
        />
    )
}

export default FormRefreshPassword;