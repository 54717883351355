/**
 * Функция для добавления месяца к переданной дате.
 * @param {Date} date - Исходная дата.
 * @returns {Date} Новая дата, увеличенная на месяц и один день.
 */
function addMonth(date) {
    // Создаем новую дату на основе переданной
    const newDate = new Date(date);

    // Увеличиваем месяц на 1
    newDate.setMonth(newDate.getMonth() + 1);

    return newDate;
}

/**
 * Функция для форматирования даты в строку в формате "YYYY-MM-DD".
 * @param {Date} date - Дата для форматирования.
 * @returns {string} Дата в формате строки "YYYY-MM-DD".
 */
function formatDateToString(date) {
    // Получаем год, месяц и день из переданной даты
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Прибавляем 1, так как нумерация месяцев начинается с 0
    const day = String(date.getDate()).padStart(2, '0');

    // Объединяем полученные значения в строку в формате "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

/**
 * Функция для добавления месяца и одного дня к переданной дате.
 * @param {Date} date - Исходная дата.
 * @returns {string} Новая дата в формате строки "YYYY-MM-DD", увеличенная на месяц и один день.
 */
function getDateAddMonthAndOneDay(date) {
    // Создаем новую дату на основе переданной
    const newDate = addMonth(date)
    // Объединяем полученные значения в строку в формате "YYYY-MM-DD"
    const formattedDate = formatDateToString(newDate)

    return formattedDate;
}

export {
    getDateAddMonthAndOneDay,
    formatDateToString,
}