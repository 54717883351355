import React from "react"
import ListWithOnClickElement from "../../../../commonComponents/pages/ListWithOnClickElement/ListWithOnClickElement"
import ItemClientAbonement from "./components/ItemClientAbonement";
import { useStudio } from "../../../../context/studioContext";

/** Список абонементов клиента */
function ListClentAbonements({ abonements }) {
    const { objStyles } = useStudio();
    return (
        <>
            <p className={`title margin-top__30px ${objStyles.colorText}`}>История абонементов</p>
            <ListWithOnClickElement
                array={abonements}
                children={(abonement) => <ItemClientAbonement abonement={abonement} />}
            />
        </>
    )
}

export default ListClentAbonements;