import React from "react";
import FormAdd from "../../../../../../../../commonComponents/forms/FormAdd/FormAdd";
import InputText from "../../../../../../../../commonComponents/inputs/InputText/InputText";
import useLogicFormAddorUpdateClient from "./logic/useLogicFormAddorUpdateClient";

function FormAddorUpdateClient({
    client, // если передан то автоматически редактирование
    textButtonSubmit, // текст кнопки сабмита
    handleSuccessAdd, // успешное выполнение
    handleSuccessUpdate,
}) {

    const {
        nameClient,
        subnameClient,
        number,
        email,
        validMessage,
        setValidMessage,
        isSubmitDisabled,
        handleChangeName,
        handleChangeSubname,
        handleChangeNumber,
        handleChangeEmail,
        handleSubmit,
        isLoading
    } = useLogicFormAddorUpdateClient({ handleSuccessAdd, handleSuccessUpdate, client });

    return (
        <FormAdd
            onSubmit={handleSubmit}
            text={!client ? 'Добавить клиента' : 'Редактировать'}
            textButtonSubmit={textButtonSubmit}
            validationOptions={isSubmitDisabled} // дисейблить сабмит если true
            textForFill={validMessage}
            cleanTextForFill={() => setValidMessage()}
            isLoading={isLoading}
            buttonUnderText={validMessage}
            children={
                <>
                    <InputText
                        inputValue={nameClient}
                        setInputChange={handleChangeName}
                        placeholder={"Введите имя"}
                        //bottomText={validationMassegeName}
                        label={"Имя"}
                    />
                    <InputText
                        inputValue={subnameClient}
                        setInputChange={handleChangeSubname}
                        placeholder={"Введите фамилию"}
                        //bottomText={validationMassegeSubname}
                        label={"Фамилия"}
                    />
                    <InputText
                        inputValue={number}
                        setInputChange={handleChangeNumber}
                        placeholder={"Телефон"}
                        //bottomText={validationMassegeNumber}
                        label={"Тел +7"}
                    />
                    <InputText
                        inputValue={email}
                        setInputChange={handleChangeEmail}
                        placeholder={"email (не обязательно)"}
                        //bottomText={validationMassegeEmail}
                        label={"Email"}
                        isRequired={false}
                    />
                </>
            }
        />
    )
}

export default FormAddorUpdateClient;