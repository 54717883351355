import React from "react";
import ModalWithChildren from "../../../../commonComponents/modal/ModalWithChildren";
import FormChoiceConfirmOrRemove from "./forms/FormChoiceConfirmOrRemove";
import useLogicNonMembershipVisitsModalAndForms from "./logic/useLogicNonMembershipVisitsModalAndForms";
import FormConfirmNonmembershipVisit from "./forms/FormConfirmNonmembershipVisit/FormConfirmNonmembershipVisit";
import FormRemoveNonMembershipVisit from "./forms/FormRemoveNonMembershipVisit/FormRemoveNonMembershipVisit";

const NonMembershipVisitsModalAndForms = ({
    visit,
    isOpen,
    closeModal,
    handleSuccessConfirm,
    handleRemove,
}) => {

    const {
        handleSuccess,
        form, setForm,
        closeForms,
    } = useLogicNonMembershipVisitsModalAndForms({
        handleSuccessConfirm,
        handleRemove,
        closeModal
    });

    return (
        <ModalWithChildren
            isOpen={isOpen}
            isClose={closeForms}
            children={
                <>
                    {form === 'FormChoiceConfirmOrRemove' &&
                        <FormChoiceConfirmOrRemove
                            openFormConfirmVisit={() => setForm('FormConfirmNonmembershipVisit')}
                            opeFormremoveVisit={() => setForm('FormRemoveNonMembershipVisit')}
                        />
                    }
                    {form === 'FormConfirmNonmembershipVisit' &&
                        <FormConfirmNonmembershipVisit
                            visit={visit}
                            handleCreate={handleSuccess}
                            closeModal={closeForms}
                        />
                    }
                    {form === 'FormRemoveNonMembershipVisit' &&
                        <FormRemoveNonMembershipVisit
                            visit={visit}
                            closeForm={closeForms}
                            handleSucces={() => handleSuccess('remove')}
                        />
                    }
                </>
            }
        />
    )
}

export default NonMembershipVisitsModalAndForms;