import { useNavigate } from "react-router-dom";
import { useStudio } from "../../../context/studioContext";
import { getCompanyBranchesAsyncLocalStorage, saveStudioValueAsyncLocalStorage } from "../../../utils/localStorage/studioChoice";

/** Компонент выбора студии */
function ChoiserStudio({ whiteFlex, companyBranches }) {
    const { setStudio } = useStudio();
    const navigate = useNavigate();
    async function choiceStudioName(studioName) {
        try {
            const findedStudio = companyBranches.find((br) => br.name === studioName);
            await saveStudioValueAsyncLocalStorage(findedStudio);
            setStudio(findedStudio);
            window.location.reload();
        }
        catch (err) {
            console.log(err);
        }
    }

    function isSeparator(number) {
        return number % 2 === 0;
    }

    function isShowStudio(name) {
        if (companyBranches && companyBranches.some(br => br.name === name)) {
            return true
        } else {
            return false
        }
    }

    return (
        companyBranches &&
        <div className="choiser-studio">
            <>
                {isShowStudio('Flex Lab') &&
                    <>
                        <button
                            className={`choiserStudioPage__link-page ${whiteFlex ? 'choiserStudioPage__link-page_white' : ''}`}
                            onClick={() => choiceStudioName('Flex Lab')}>
                            {'Flex Lab'}
                        </button>
                        <div className={`choiserStudioPage__separator ${whiteFlex ? 'choiserStudioPage__separator_white' : ''}`}></div>
                    </>
                }
                {isShowStudio('DARK') &&
                    <button
                        className={`choiserStudioPage__link-page choiserStudioPage__link-page_Dark `}
                        onClick={() => choiceStudioName('DARK')}>
                        {'DARK'}
                    </button>}

            </>

            {/* return (
            <div className="choiserStudioPage__container" key={index}>
                <>

                    <button
                        className={`choiserStudioPage__link-page
                        ${whiteFlex && branch.name.includes('Flex') ? 'choiserStudioPage__link-page_white' : ''}
                        ${branch.name.includes('DARK') ? 'choiserStudioPage__link-page_Dark' : ''}`}
                        onClick={() => choiceStudioName(branch.name)}>
                        {branch.name}
                    </button>
                    {index < companyBranches.length - 1 &&
                        <div className={`choiserStudioPage__separator ${whiteFlex ? 'choiserStudioPage__separator_white' : ''}`}></div>}
                </>
            </div > */}

        </div >
    )
}

export default ChoiserStudio;