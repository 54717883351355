import { useEffect, useState } from "react";
import { getTrainersByCompanyBranchId } from "./functions";
import { useStudio } from "../../../../../../context/studioContext";
import { sortBySurname } from "../../utils/functions";
import { filterTrainersByString } from "./functions";


function useLogicTrainersList() {

    const { studio } = useStudio();
    const [initList, setInitList] = useState(undefined);
    const [list, setList] = useState([]);
    const [openedForm, setOpenedForm] = useState('nameForm');

    const [isLoadingFill, setIsLdingFill] = useState(false);

    function addTrainer(data) {
        const updatedList = [...list]
        updatedList.push(data);
        setList(updatedList);
        setOpenedForm();
    }

    async function fill() {
        try {
            setIsLdingFill(true);
            let data = await getTrainersByCompanyBranchId(studio._id);
            const sortedBuSurname = sortBySurname(data.trainers)
            setList(sortedBuSurname);
            setIsLdingFill(false);
        }
        catch (err) {
            setIsLdingFill(false);
        }
    }

    function handleSubmOnSearch(value) {
        if (!initList) {
            setInitList(list);
        }
        setTimeout(() => {
            if (initList) {
                const filtredTrainers = filterTrainersByString(initList, value);
                setList(filtredTrainers);
                if (value === '') {
                    setList(initList)
                }
            }
        })
    }

    useEffect(() => {
        fill()
    }, [])

    return {
        list,
        openedForm,
        setOpenedForm,
        addTrainer,
        isLoadingFill,
        handleSubmOnSearch,
    };
}

export default useLogicTrainersList;
