import { useEffect, useState } from "react";
import apiTrainer from "../../../../../../../../../../../api/apiTrainer";
import { useStudio } from "../../../../../../../../../../../context/studioContext";

function useLogicFormAddCurrentTrainer({ handleSuccessAdd }) {
    const { studio } = useStudio();
    const [trainers, setTrainers] = useState([]);
    const [isLoaing, setIsLoaing] = useState(false);
    const [validMessage, setValidMessage] = useState();
    const [openedForm, setOpenedForm] = useState('')
    const [selectedTrainer, setSelectedTrainer] = useState();
    const [isLoadingTrainer, setIsLoadingTrainer] = useState(false);

    async function getTrainers() {
        try {
            setIsLoaing(true);
            const data = await apiTrainer.getTrainersByCompanyId(studio.company)
            setTrainers(data.trainers);
            setIsLoaing(false);
        }
        catch (err) {
            setIsLoaing(false);
            console.log(err)
            setValidMessage(err.message)
        }
    }

    function selectTrainer(data) {
        setSelectedTrainer(data);
        setOpenedForm('FormConfirm')
    }

    async function addTrainer() {
        try {
            setIsLoadingTrainer(true);
            const trainer = await apiTrainer.controlTrainerCompanyBranch({ companyBranchId: studio._id, trainerId: selectedTrainer._id, action: 'push' })
            setIsLoadingTrainer(false);
            handleSuccessAdd(trainer);
        }
        catch (err) {
            setIsLoadingTrainer(false);
            setValidMessage(err.message)
        }
    }

    useEffect(() => {
        if (studio) {
            getTrainers()
        }
    }, [studio])

    return ({
        trainers,
        validMessage,
        isLoaing,
        selectTrainer,
        addTrainer,
        openedForm, setOpenedForm,
        selectedTrainer,
        isLoadingTrainer,
    })
}

export default useLogicFormAddCurrentTrainer;