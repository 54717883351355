import { useState } from "react"
import apiTraining from "../../../../../../../../api/apiTraining";
import { useStudio } from "../../../../../../../../context/studioContext";

function useLogicFormRemovetraining({ trainingId, handleSuccessRemoveTraining }) {
    const {studio} = useStudio();
    const [isLoading, setIsLoading] = useState();
    const [validText, setValidText] = useState();

    async function removeTraining() {
        try {
            setIsLoading(true);
            await apiTraining.removeTraining({trainingId, companyBranchId: studio._id});
            handleSuccessRemoveTraining();
            setIsLoading(false);
        }
        catch (err) {
            setValidText(err.message);
            setIsLoading(false);
        }
    }

    return ({
        removeTraining,
        isLoading,
        validText, setValidText
    })
}

export default useLogicFormRemovetraining;