import { useState } from "react";
import apiTraining from "../../../../../../api/apiTraining";
import { useStudio } from "../../../../../../context/studioContext";

function useLogicFormRemoveVisit({ client, training, handleSuccessRemoveVisit }) {
    const { studio } = useStudio();
    const [isLoading, setIsLoading] = useState(false);
    const [valdMessage, setValdMessage] = useState();

    async function removeVisit() {
        try {
            setIsLoading(true);
            await apiTraining.removeClientByIdToVisitList({ clientId: client._id, trainingId: training._id, companyBranchId: studio._id })
            handleSuccessRemoveVisit();
            setIsLoading(false);
        }
        catch (err) {
            setValdMessage(err.message)
            setIsLoading(false);
        }
    }

    return ({
        removeVisit,
        isLoading,
        valdMessage, setValdMessage,
    })
}

export default useLogicFormRemoveVisit;