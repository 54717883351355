import { useStudio } from "../../../context/studioContext";
import Loader from "../../loaders/Loader/Loader";
//import Loader from "../../loaders/Preloader/Preloader";

function ButtonWithBorder({ text, onClick, isLoading, isDisabled, colorButtonSubmit }) {
    const { objStyles } = useStudio();
    function click() {
        if (!isDisabled) {
            onClick();
        }
    }

    return (
        // <div className={`button-with-border ${isDisabled ? 'button-with-border__disabled' : ''}`} onClick={click}>
        //     {!isLoading ?
        //         // <p className={`button-with-border__text ${objStyles.colorText}`}>{text}</p>
        //         <p className={`formadd__button`}>{text}</p>
        //         :
        //         <Loader />
        //     }
        // </div >
        <button
            className={`formadd__button button-with-border_margin ${colorButtonSubmit}`}
            type='button'
            onClick={onClick}
            disabled={isDisabled}>
            {!isLoading ?
                text
                : <Loader />}
        </button>
    )
}

export default ButtonWithBorder;