import React from "react";
import { formatDateToString } from "../../forms/FormAbonement/components/FormCreateOrUpdateAbonement/logic/utils/date";
import { useStudio } from "../../../../../context/studioContext";

/** Элемент списка абонементов */
function ItemClientAbonement({ abonement }) {
    const { objStyles } = useStudio();
    const optionsDate = {
        //timeZone: 'Asia/Yekaterinburg',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    };

    const formatterDate = new Intl.DateTimeFormat('ru-RU', optionsDate);
    let date = formatterDate.format(new Date(abonement.dateOn));
    let dateTo = formatterDate.format(new Date(abonement.dateTo));
    const type = abonement.type;
    const cost = abonement.cost;
    const quantity = `${abonement.quantityVisitsOn - abonement.quantityVisits} из ${abonement.quantityVisitsOn}`

    return (
        <>
            <div className="time-table-list-element" >
                <p className={`time-table-list-element__text text-align-left ${objStyles.colorText}`}>{type.customName}</p>
                <p className={`time-table-list-element__text time-table-list-element_dateTrain text-align-right ${objStyles.colorText}`}>Стоимость {cost} руб</p>
            </div>
            <div className="time-table-list-element" >
                <p className={`time-table-list-element__text text-align-left ${objStyles.colorText}`}>{date}</p>
                <p className={`time-table-list-element__text text-align-right ${objStyles.colorText}`}>{dateTo}</p>
            </div>
            <div className="time-table-list-element" >
                <p className={`time-table-list-element__text text-align-left ${objStyles.colorText}`}>Посещений</p>
                <p className={`time-table-list-element__text time-table-list-element_dateTrain text-align-right ${objStyles.colorText}`}>{quantity}</p>
            </div>
        </>
    )
}

export default ItemClientAbonement;