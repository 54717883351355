import React, { useState } from "react";
import FormAddOrUpdate from "../../../../../../../../commonComponents/forms/FormAdd/FormAdd";
import InputText from "../../../../../../../../commonComponents/inputs/InputText/InputText";
import InputSelect from "../../../../../../../../commonComponents/inputs/InputSelect/InputSelect";
import InputReactSelect from "../../../../../../../../commonComponents/inputs/InputReactSelect/InputReactSelect";
import useLogicCreateOrUpdateAbonementSettingsForm from "./logic/useLogicCreateOrUpdateAbonementSettingsForm";

function CreateOrUpdateAbonementSettingsForm({
    abonementSettings,
    trainingSettings,
    handleSuccessCreate,
    handleSuccessUpdate,
}) {

    const {
        dataSetting, setDataSetting,
        optionTrainingSettings,
        update,
        create,
        isValidForm,
        isLoading,
        validationMessage, setValidationMessage,
        changeSelectedTrainings,
    } = useLogicCreateOrUpdateAbonementSettingsForm({
        trainingSettings,
        abonementSettings,
        handleSuccessCreate,
        handleSuccessUpdate,
    });

    return (
        <FormAddOrUpdate
            text={'Тип абонемента'}
            onSubmit={abonementSettings ? update : create}
            validationOptions={isValidForm}
            textButtonSubmit={abonementSettings ? 'Редактировать' : 'Создать'}
            isLoading={isLoading}
            textForFill={validationMessage}
            cleanTextForFill={() => setValidationMessage()}
            children={
                <>
                    <InputSelect
                        label={'Тренировки'}
                        placeholder={'Выберите тип'}
                        options={optionTrainingSettings ? optionTrainingSettings : []}
                        value={dataSetting.trainingTypes}
                        onChange={(value) => setDataSetting((prev) => ({ ...prev, trainingTypes: [value] }))}
                        isRequired={true}
                    />
                    <InputText
                        label={'Название'}
                        placeholder={'Введите Название'}
                        inputValue={dataSetting.customName}
                        setInputChange={(value) => setDataSetting((prev) => ({ ...prev, customName: value }))}
                        isRequired={true}
                    />
                    {/* <InputReactSelect
                        label={'1'}
                        placeholder={'Для тренировок:'}
                        defaultValue={null}
                        setSelectValue={changeSelectedTrainings}
                        options={dataSetting.type === 'групповые' ? optionTrainingSettings.groupe : optionTrainingSettings.personal}
                        isMulti={true}
                    /> */}
                    <InputText
                        type={'number'}
                        label={'Посещения'}
                        placeholder={'Количество'}
                        inputValue={dataSetting.quantityVisits}
                        setInputChange={(value) => setDataSetting((prev) => ({ ...prev, quantityVisits: value }))}
                        isRequired={true}
                    />
                    <InputText
                        type={'number'}
                        label={'Руб'}
                        placeholder={'Введите сумму'}
                        inputValue={dataSetting.cost}
                        setInputChange={(value) => setDataSetting((prev) => ({ ...prev, cost: value }))}
                        isRequired={true}
                    />
                </>
            }
        />
    )
}

export default CreateOrUpdateAbonementSettingsForm;