import { useEffect, useState } from 'react';
import { getDateAddMonthAndOneDay } from './utils/date';
import { useStudio } from '../../../../../../../context/studioContext';
import useEfects from "./hooks/useEffects";
import apiAbonementSettings from '../../../../../../../api/apiAbonementSettings';
import apiNonMemberShipVisit from '../../../../../../../api/apiNonMemberShipVisit';

function useLogicFormCreateOrUpdateAbonement({
    visit,
    handleCreate,
}) {
    const { studio } = useStudio();
    // Стейты, в которых содержятся значения инпута и информационный текст
    const [abonementType, setAbonementType] = useState();
    const [dateOn, setDateOn] = useState(new Date());
    const [dateTo, setDateTo] = useState(getDateAddMonthAndOneDay(new Date()));
    const [quantity, setQuantity] = useState('1'); // количество тренировок
    const [cost, setCost] = useState('0'); // стоимость абонемента
    //Лоадер
    const [isLoading, setIsLoading] = useState(false);
    // Валидация
    const [isValidForm, setIsValidForm] = useState(false);
    const [submitText, setSubmitText] = useState();
    const [isDisabledInputs, setIsDisabledInputs] = useState(false);

    const [abonementSettigs, setAbonementSettigs] = useState({
        groupeAbonementSettings: [],
        personalAbonementSettings: [],
    });
    const [abonementSettigsInputOptions, setAbonementSettigsInputOptions] = useState([]);

    async function onSubmit() {
        try {
            setIsLoading(true);
            const data = {
                nonMembershipVisitsId: visit._id,
                companyBranchId: studio._id,
                clientId: visit.client._id,
                abonementDateOn: new Date(dateOn).toISOString(),
                abonementDateTo: new Date(dateTo).toISOString(),
                abonementQuantityVisitsOn: Number(quantity),
                abonementType,
                abonementCost: Number(cost),
            }
            
            const newAbonement = await apiNonMemberShipVisit.confirm(data)
            handleCreate(newAbonement)
            setIsLoading(false);
        }
        catch (err) {
            console.log(err);
            setSubmitText(err.message);
            setIsLoading(false);
        }

    }

    async function fillAbonementsSettingsOptions() {
        try {
            const abonementSettigs = await fillAbonementSettings()
            const options = abonementSettigs.map((setting) => {
                const label = `${setting.customName}`
                const obj = { value: setting._id, label, setting: setting }
                return obj;
            })
            console.log(options)
            setAbonementSettigsInputOptions(options)
        }
        catch (err) {

        }
    }

    async function fillAbonementSettings() {
        try {
            const data = await apiAbonementSettings.getAbonementSettingsByCompanyBranchId(studio._id);
            const groupeAbonementSettings = data.abonementSettings.filter((setting) => setting.trainingTypes.some((type) => type.type.includes('групп')))
            const personalAbonementSettings = data.abonementSettings.filter((setting) => setting.trainingTypes.some((type) => type.type.includes('перс')))
            const options = groupeAbonementSettings.concat(personalAbonementSettings);
            setAbonementSettigs(options)
            return options;
                
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fillAbonementsSettingsOptions()
    }, [])

    useEffect(() => {
        // if (visit) {
        //     const type = visit?.training?.typeOfTraining.type.includes("групп") ? 'групп' : 'перс';
        //     setAbonementType(type);
        // }
    }, [visit])


    useEfects({
        setDateOn,
        setDateTo,
        setIsValidForm,
        abonementType, dateOn, dateTo, quantity, cost,
        abonementSettigsInputOptions,
        abonementSettigs,
        setQuantity,
        setCost,
        setIsDisabledInputs,
    })


    return {
        abonementType,
        setAbonementType,
        dateOn,
        setDateOn,
        dateTo,
        setDateTo,
        quantity,
        setQuantity,
        cost,
        setCost,
        isValidForm,
        submitText,
        setSubmitText,
        isLoading,
        isDisabledInputs,
        abonementSettigsInputOptions,
        //Функции
        onSubmit,
    };
}

export default useLogicFormCreateOrUpdateAbonement;
