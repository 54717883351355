import React from "react";
import ModalWithChildren from "../../../../../../commonComponents/modal/ModalWithChildren";
import CreateOrUpdateAbonementSettingsForm from "./forms/CreateOrUpdateAbonementSettingsForm/CreateOrUpdateAbonementSettingsForm";
import ChoiceForm from "./forms/ChoiceForm/ChoiceForm";
import useLogicModalAbonementSettingsForms from "./logic/useLogicModalAbonementSettingsForms";
import RemoveAbonementSettingForm from "./forms/RemoveAbonementSettingForm/RemoveAbonementSettingForm";

/** Компонент - модальное окно с формами для управления астройками абонементов */
function ModalAbonementSettingsForms({
    isOpen,
    isOpenCreateForm,
    handleSuccessCreate,
    handleSuccessUpdate,
    handleSuccessRemove,
    trainingSettings,
    selectedAbonementSettings,
    closeModal
}) {

    const {
        openedForm, setOpenedForm,
        openCreateOrUpdateForm,
        openRemoveForm,
        clickCloseModal,
    } = useLogicModalAbonementSettingsForms({ isOpenCreateForm, closeModal });

    return (
        <ModalWithChildren
            isOpen={isOpen}
            isClose={clickCloseModal}
            children={
                <>
                    {openedForm === 'FormAdd' &&
                        <CreateOrUpdateAbonementSettingsForm
                            abonementSettings={selectedAbonementSettings}
                            trainingSettings={trainingSettings}
                            handleSuccessCreate={handleSuccessCreate}
                            handleSuccessUpdate={handleSuccessUpdate}
                        />
                    }
                    {selectedAbonementSettings && openedForm === 'FormChoice' &&
                        <ChoiceForm
                            name={selectedAbonementSettings.customName}
                            clickUpdate={openCreateOrUpdateForm}
                            clickRemove={openRemoveForm}
                        />
                    }
                    {selectedAbonementSettings && openedForm === 'FormRemove' &&
                        <RemoveAbonementSettingForm
                            abonementSetting={selectedAbonementSettings}
                            handleSuccessRemove={handleSuccessRemove}
                            closeForm={clickCloseModal}
                        />
                    }
                </>
            }
        />
    )
}

export default ModalAbonementSettingsForms;