import { useState } from "react";

function useModalWithForm({ isClose }) {

    const [openedForm, setOpenedForm] = useState('FormChoice');

    function closeForm() {
        setOpenedForm('FormChoice');
        isClose();
    }

    return ({
        openedForm, setOpenedForm,
        closeForm
    })
}

export default useModalWithForm;