import React from "react";

/** Компонент отображения информации о стоимост тренировок для тренера (оплаты) */
function TrainerPaymentSettings({ paymentSetting, onClick }) {
    
    return (
        <div className="trainer-payment-setting" onClick={onClick}>
                <p className="trainer-payment-setting__text">
                    Оплата за группы: {paymentSetting.groupePaymentType === 'client' ? 'за клиента' : 'за тренирвоку'} {paymentSetting.groupePayment} руб
                </p>
                <p className="trainer-payment-setting__text">
                    Оплата за перс: {paymentSetting.personalPaymentType === 'procent' ? '' : 'за тренирвоку'} {paymentSetting.personalPayment} %
                </p>
        </div>
    )
}

export default TrainerPaymentSettings;