import Loader from "../../loaders/Loader/Loader";
import ButtonWithBorder from "../../buttons/ButtonWithBorder/ButtonWithBorder";

function FormAdd({
    text, // заголовок формы
    onSubmit, // самбмит формы
    validationOptions,
    titleColorControl,
    textButtonSubmit,
    colorButtonSubmit, // стиль кнопки сабмита
    buttonUnderText,
    isLoading,
    textForFill, // текст для заполнения формы им (например при успешном сабмите)
    cleanTextForFill, // очистить текст для заолнения
    children,
}) {

    function submit(e) {
        e.preventDefault();
        onSubmit();
    }

    return (
            <form onSubmit={submit}>
                {textForFill ?
                    <div className="formadd__text-fill-container">
                        <p className="formadd__text-fill">{textForFill}</p>
                        <ButtonWithBorder
                            text={'Назад'}
                            onClick={cleanTextForFill}
                        />
                    </div>
                    :
                    <>
                        <p className={`formadd__title ${titleColorControl}`}>{text}</p>
                        <div className="formadd__set">
                            {children}
                        </div>
                        <button className={`formadd__button ${colorButtonSubmit}`} type="submit" disabled={validationOptions}>{!isLoading ? textButtonSubmit : <Loader />}</button>
                        <span className="formadd__button-under-text">{buttonUnderText}</span>
                    </>
                }
            </form>
    )
}

export default FormAdd;