/** Измнение стейтов стоимости и количества для кастомного абона */
function setStateForCustom(type, setState, value) {
    if (type.includes('настр')) {
        const onlyNumber = removeNonNumericCharacters(value);
        setState(onlyNumber)
    }
}

// Функция валидации
function validateState(state) {
    return state !== undefined && state !== null;
}

function validateAllStates(...states) {
    return states.every(validateState);
}

function removeNonNumericCharacters(str) {
    return str.replace(/\D/g, '');
}


export {
    setStateForCustom,
    validateAllStates,
}