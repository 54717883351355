import apiClient from "../../../api/apiClient";

async function getClientsByCompanyId(companyId) {
    const clients = await apiClient.getClientsByCompanyId(companyId);
    return clients;
}

async function getClientsByCompanyBranchId(branchId) {
    const clients = await apiClient.getClientsByCompanyBranchId(branchId);
    return clients;
}

export {
    getClientsByCompanyId,
    getClientsByCompanyBranchId,
}