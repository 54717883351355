import ButtonWithBorder from "../../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";

/** Форма для выбора работы с клиентом */
function FormChoiceActionClient({
    openUpdateForm,
    openPasswordRefreshForm,
}) {
    return (
        <>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Редактировать'} onClick={openUpdateForm} />
            </div>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Написать'} onClick={openUpdateForm} />
            </div>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Отправить логин и пароль'} onClick={openPasswordRefreshForm} />
            </div>
        </>
    )
}

export default FormChoiceActionClient;