import apiTrainer from "../../../../../../api/apiTrainer";

function filterTrainersByString(objects, searchString) {
    return objects.filter(obj => {
        // Проверяем наличие совпадений в поле name
        const nameMatch = obj.name.toLowerCase().includes(searchString.toLowerCase());
        // Проверяем наличие совпадений в поле surname
        const surnameMatch = obj.surname.toLowerCase().includes(searchString.toLowerCase());
        // Проверяем наличие совпадений в поле phoneNumber
        const phoneNumberMatch = obj.contactData.phoneNumber.includes(searchString);
        
        // Возвращаем true, если хотя бы одно из полей содержит совпадение
        return nameMatch || surnameMatch || phoneNumberMatch;
    });
}

async function getTrainersByCompanyId(companyId) {
    const trainers = await apiTrainer.getTrainersByCompanyId(companyId);
    return trainers;
}

async function getTrainersByCompanyBranchId(branchId) {
    const trainers = await apiTrainer.getTrainersByCompanyBranchId(branchId);
    return trainers;
}

export {
    filterTrainersByString,
    getTrainersByCompanyId,
    getTrainersByCompanyBranchId,
}