import React from "react";
import FormRemove from "../../../../../../../commonComponents/forms/FormRemove/FormRemove";
import useLogicFormRemovetraining from "./logic/useLogicFormRemovetraining";

function FormRemoveTraining({ trainingId, handleSuccessRemoveTraining, closeForm }) {

    const {
        removeTraining,
        isLoading,
        validText, setValidText
    } = useLogicFormRemovetraining({ trainingId, handleSuccessRemoveTraining })

    return (
        <FormRemove
            onRemove={removeTraining}
            isLoading={isLoading}
            closeForm={closeForm}
            textForFill={validText}
            cleanTextForFill={()=>setValidText()}
        />
    )
}

export default FormRemoveTraining;