import { useEffect } from "react";
import { formatDateToString, getDateAddMonthAndOneDay } from "../utils/date";
import { validateAllStates } from "../utils/validation";
import { useUserContext } from "../../../../../../../../../context/userContext";

function useEfects({
    abonement,
    setDateOn,
    setDateTo,
    setIsValidForm,
    abonementType, dateOn, dateTo, quantity, cost,
    abonementSettigsInputOptions,
    setQuantity,
    setCost,
    setAbonementType,
    setIsDisabledInputs,
}) {

    const { userData } = useUserContext();

    //Дата
    useEffect(() => {
        if (dateOn) {
            setDateTo(getDateAddMonthAndOneDay(dateOn))
        }
    }, [dateOn])

    useEffect(() => {
        const formattedDate = formatDateToString(new Date())
        setDateOn(formattedDate)
    }, [])

    //Валидация
    useEffect(() => {
        const allStatesValid = validateAllStates(abonementType, dateOn, dateTo, quantity, cost);
        setIsValidForm(allStatesValid);
    }, [abonementType, dateOn, dateTo, quantity, cost])

    //Если редактрование
    useEffect(() => {
        console.log(abonement)
    }, [abonement])

    useEffect(() => {
        if (abonementSettigsInputOptions && abonementType) {
            const finded = abonementSettigsInputOptions.find((setting) => setting.setting._id === abonementType)
            if (finded) {
                setQuantity(finded.setting.quantityVisits)
                setCost(finded.setting.cost)
                const isDisabledInputs = userData.role !== 'superAdmin';
                setIsDisabledInputs(isDisabledInputs)
            }
        }

    }, [abonementSettigsInputOptions, abonementType])
    return ({

    })
}

export default useEfects;