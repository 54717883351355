import React, { useEffect, useState } from 'react';
import ChoiserStudio from '../../commonComponents/pages/choiserStudio/ChoiserStudio';
import { getCompanyBranchesAsyncLocalStorage } from '../../utils/localStorage/studioChoice';

function ChoiserStudioPage() {

    const [companyBranches, setCompanyBranches] = useState([]);

    async function getCompanyBranches() {
        try {
            const branches = await getCompanyBranchesAsyncLocalStorage();
            if (branches) {
                setCompanyBranches(branches);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(()=>{
        getCompanyBranches();
    },[])

    return (
        <div className='choiserStudioPage'>
            <div className='choiserStudioPage__link-container'>
                <ChoiserStudio companyBranches={companyBranches}/>
            </div>
        </div>
    )
}

export default ChoiserStudioPage;