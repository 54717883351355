import ButtonWithBorder from "../../../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";

/** Форма для выбора работы с тренировкой */
function FormChoiceActionTraining({
    nameForm,
    openUpdateForm,
    openRemoveForm,
    openTrainingPage,
}) {
    return (
        <>
            <p className="formadd__title">{nameForm}</p>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Записи'} onClick={openTrainingPage} />
            </div>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Редактировать'} onClick={openUpdateForm} />
            </div>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Удалить'} onClick={openRemoveForm} />
            </div>
        </>
    )
}

export default FormChoiceActionTraining;