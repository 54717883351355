import { useStudio } from "../../../context/studioContext";
import AbonementNameAndIndicator from "../AbonementNameAndIndicator/AbonementNameAndIndicator";

/** Информация о клиенте и его абонементе */
function ClientInfo({ client, handleClickOnAbonement = () => { }, handleClickOnClient = () => { }, fullInfo = true }) {
    const { objStyles } = useStudio();

    const phoneNumber = client.contactData?.phoneNumber ? client.contactData.phoneNumber : '';
    return (
        <div className='client-info'>
            <p className={`client-info__text font-weight-bold ${objStyles.colorText}`} onClick={handleClickOnClient ? () => handleClickOnClient(client) : () => { }}>
                {client.surname} {client.name}
            </p>
            {phoneNumber && fullInfo &&
                <p className={`client-info__text font-weight-medium ${objStyles.colorText}`}>
                    {phoneNumber}
                </p>}
            {fullInfo &&
                <AbonementNameAndIndicator
                    abonement={client.groupeAbonement}
                    type={'группа'}
                    handleClickOnAbonement={handleClickOnAbonement ? () => handleClickOnAbonement('группа', client.groupeAbonement) : () => { }}
                />}
            {fullInfo &&
                <AbonementNameAndIndicator
                    abonement={client.personalAbonement}
                    type={'перс'}
                    handleClickOnAbonement={handleClickOnAbonement ? () => handleClickOnAbonement('перс', client.personalAbonement) : () => { }}
                />}
        </div>
    )
}

export default ClientInfo;