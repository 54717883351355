import { useEffect } from "react";
import { formatDateToString, getDateAddMonthAndOneDay } from "../utils/date";
import { validateAllStates } from "../utils/validation";

function useEfects({
    setDateOn,
    setDateTo,
    setIsValidForm,
    abonementType, dateOn, dateTo, quantity, cost,
    abonementSettigsInputOptions,
    abonementSettigs,
    setQuantity,
    setCost,
    setIsDisabledInputs,

}) {
    //Дата
    useEffect(() => {
        if (dateOn) {
            setDateTo(getDateAddMonthAndOneDay(dateOn))
        }
    }, [dateOn])

    useEffect(() => {
        const formattedDate = formatDateToString(new Date())
        setDateOn(formattedDate)
    }, [])

    //Валидация
    useEffect(() => {
        const allStatesValid = validateAllStates(abonementType, dateOn, dateTo, quantity, cost);
        setIsValidForm(allStatesValid);
    }, [abonementType, dateOn, dateTo, quantity, cost])


    useEffect(() => {
        if (abonementSettigs && abonementType) {
            const finded = abonementSettigs.find((setting) => setting._id === abonementType)
           
            if (finded) {
                setQuantity(finded.quantityVisits)
                setCost(finded.cost)
                const isDisabledInputs = !finded.customName.includes('настр')
                setIsDisabledInputs(isDisabledInputs)
            }
        }

    }, [abonementSettigsInputOptions, abonementType])

    
    return ({

    })
}

export default useEfects;