import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from "react-datepicker";

function InputDateTimePicker({ handleSetDateTime, initDate, validationText, label }) {

  const [selectedDate, setSelectedDate] = useState(new Date());


  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleSetDateTime(date);
  };

  useEffect(() => {
    // Получаем текущую локаль браузера и устанавливаем её как локаль для react-datepicker
    const browserLocale = window.navigator.language;
    if (browserLocale) {
      registerLocale(browserLocale);
      setDefaultLocale(browserLocale);
    }
  }, []);

  useEffect(() => {
    if (initDate) {
      handleSetDateTime(initDate);
      setSelectedDate(initDate)
    }
  }, [initDate])

  return (
    <div>
      <div className='input-date-time-picker'>
        <p className='input__label'>{label}</p>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="Время"
          dateFormat="dd.MM.yyyy HH:mm"
          className="date-picker"
        />
      </div>
      <span className="input__text-error" id="error-firstname">{validationText}</span>
    </div>
  )
}

export default InputDateTimePicker;