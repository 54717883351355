import React from "react";
import ModalWithChildren from "../../../../../../../../commonComponents/modal/ModalWithChildren";
import FormAddorUpdateClient from "./FormAddorUpdateClient";

function FormAddClient({
    isOpen,
    isClose,
    handleSuccessAdd,
}) {
    return (
        <ModalWithChildren
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={isClose} // клик на кнопку для сворачивания
            children={
                <FormAddorUpdateClient
                    textButtonSubmit={'Создать'}
                    handleSuccessAdd={handleSuccessAdd}
                />
            }
        />
    )
}

export default FormAddClient;