import { host } from "./const";

const apiTranerPaymentSetting = {
    host: host,

    update(data) {
        return fetch(`${this.host}/payment-trainer-setting`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    }, 

}

export default apiTranerPaymentSetting;