import { useState } from "react";
import apiNonMemberShipVisit from "../../../../../../../api/apiNonMemberShipVisit";
import { useStudio } from "../../../../../../../context/studioContext";

function useLogicFormRemoveNonMembershipVisit({
    visit,
    closeForm,
    handleSucces
}) {
    const { studio } = useStudio();
    const [isLoading, setIsloading] = useState(false);
    const [validateMessage, setValidMessage] = useState();

    async function removeNonMemberShipVisit() {
        try {
            setIsloading(true);
            const data = {
                companyBranchId: studio._id,
                clientId: visit.client._id,
                nonMembershipVisitsId: visit._id,
            }
            await apiNonMemberShipVisit.remove(data);
            handleSucces();
            closeForm();
            setIsloading(false);
        }
        catch (err) {
            setValidMessage(err.message);
            setIsloading(false);
        }
    }

    return ({
        isLoading,
        validateMessage, setValidMessage,
        removeNonMemberShipVisit,
    })
}

export default useLogicFormRemoveNonMembershipVisit;