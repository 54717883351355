/**
 * Асинхронно сохраняет массив филиалов компании в локальное хранилище.
 * @param {Array<Object>} companyBranches - Массив объектов, представляющих филиалы компании.
 * @param {string} companyBranches[].companyId - Идентификатор компании, к которой относится филиал.
 * @param {string} companyBranches[].branchId - Идентификатор филиала.
 * @param {string} companyBranches[].name - Название филиала.
 * @param {Array<Object>} companyBranches[].halls - Массив объектов, представляющих залы филиала.
 * @param {string} companyBranches[].halls[].id - Идентификатор зала.
 * @param {string} companyBranches[].halls[].hallName - Название зала.
 * @param {number} companyBranches[].halls[].capacity - Вместимость зала.
 * @returns {void}
 */
async function saveCompanyBranchesAsyncLocalStorage(companyBranches) {
    localStorage.setItem('companyBranches', JSON.stringify(companyBranches));
    console.log('Значение "companyBranches" успешно сохранено в Local Storage.');
}

/**
 * Сравнивает массив филиалов с существующим и обновляет, если пришел новый.
 * @param {Array<Object>} checkedCompanyBranches - Массив объектов, представляющих филиалы компании.
 * @returns {void}
 */
async function refreshCompanyBranchesAsyncLocalStorage(checkedCompanyBranches) {
    let currentCompanyBranches = await getCompanyBranchesAsyncLocalStorage();
    console.log(currentCompanyBranches)
    const isSame = currentCompanyBranches && JSON.stringify(currentCompanyBranches) === JSON.stringify(checkedCompanyBranches);
    
    // function areArraysEqual(arr1, arr2) {
    //     if (arr1.length !== arr2.length) {
    //         return false;
    //     }
    
    //     return arr1.every((obj, index) => {
    //         return JSON.stringify(obj) === JSON.stringify(arr2[index]);
    //     });
    // }
    //const isSame = currentCompanyBranches && areArraysEqual(checkedCompanyBranches, currentCompanyBranches)
    
    if (!isSame) {
        await saveCompanyBranchesAsyncLocalStorage(checkedCompanyBranches);
    }
}

/**
 * Асинхронно получает массив филиалов компании из локального хранилища.
 * @returns {Array<Object> | null} Массив объектов, представляющих филиалы компании, или null, если данные не найдены.
 */
async function getCompanyBranchesAsyncLocalStorage() {
    let result = localStorage.getItem('companyBranches');
    const companyBranches = result ? JSON.parse(result) : null;
    console.log('Значение "companyBranches" успешно получено в Local Storage.');
    return companyBranches;
}

/**
 * Асинхронно сохраняет значение филиала компании "studio" в локальное хранилище.
 * @param {Object} studio - Объект, представляющий филиал компании.
 * @returns {void}
 */
async function saveStudioValueAsyncLocalStorage(studio) {
    localStorage.setItem('studio', JSON.stringify(studio));
    console.log('Значение "studio" успешно сохранено в Local Storage.');
}

/**
 * Асинхронно получает значение филиала компании "studio" из локального хранилища.
 * @returns {Object | null} Объект, представляющий филиал компании "studio", или null, если данные не найдены.
 */
async function getStudioValueAsyncLocalStorage() {
    let result = localStorage.getItem('studio');
    let studio = result ? JSON.parse(result) : null;
    return studio;
}

export {
    saveCompanyBranchesAsyncLocalStorage,
    refreshCompanyBranchesAsyncLocalStorage,
    saveStudioValueAsyncLocalStorage,
    getStudioValueAsyncLocalStorage,
    getCompanyBranchesAsyncLocalStorage,
}