function sortBySurname(objectsArray) {
    // Используем метод sort() для сортировки массива по свойству surname
    objectsArray.sort((a, b) => {
        // Приводим фамилии к нижнему регистру для корректного сравнения
        const surnameA = a.surname.toLowerCase();
        const surnameB = b.surname.toLowerCase();

        // Сравниваем фамилии объектов
        if (surnameA < surnameB) {
            return -1; // Если фамилия a предшествует фамилии b в алфавитном порядке
        }
        if (surnameA > surnameB) {
            return 1; // Если фамилия a следует после фамилии b в алфавитном порядке
        }
        return 0; // Если фамилии идентичны
    });

    // Возвращаем отсортированный массив
    return objectsArray;
}

export {
    sortBySurname,
}