import { useEffect, useState } from "react";

function useLogicModalAbonementSettingsForms({ isOpenCreateForm, closeModal }) {

    const [openedForm, setOpenedForm] = useState('FormChoice')

    function openCreateOrUpdateForm() {
        setOpenedForm('FormAdd')
    }

    function openRemoveForm() {
        setOpenedForm('FormRemove')
    }

    function clickCloseModal() {
        closeModal()
        setTimeout(() => setOpenedForm('FormChoice'), 1000)
    }

    useEffect(() => {
        if (isOpenCreateForm) {
            setOpenedForm('FormAdd')
        }
    }, [isOpenCreateForm])

    return ({
        openedForm, setOpenedForm,
        openCreateOrUpdateForm,
        openRemoveForm,
        clickCloseModal,
    })
}

export default useLogicModalAbonementSettingsForms;