import { useState } from "react";
import apiAbonementSettings from "../../../../../../../../../api/apiAbonementSettings";
import { useStudio } from "../../../../../../../../../context/studioContext";

const useLogicRemoveAbonementSettingForm = ({ abonementSetting, handleSuccessRemove }) => {

    const { studio } = useStudio();
    const [isLoading, setIsLoading] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    function clearValidationMessage() {
        setValidationMessage();
    }
    async function removeAbonementSetting() {
        try {
            setIsLoading(true);
            await apiAbonementSettings.remove({
                abonementSettingsId: abonementSetting._id,
                companyBranchId: studio._id
            })
            handleSuccessRemove();
            setIsLoading(false);
        }
        catch (err) {
            setValidationMessage(err.message);
            setIsLoading(false);
        }
    }

    return ({
        isLoading,
        validationMessage, clearValidationMessage,
        removeAbonementSetting,
    })
}

export default useLogicRemoveAbonementSettingForm;