import React from "react";
import { formatTime } from "../../../utils/date";

function TitleClientListOnTrainingPage({training}){
    return(
        <p className={`client-list-on-training-page__title font-weight-bold`}>{`
                ${training?.name}
                c 
                ${training ? formatTime(training?.dateOn) : ''}
                до 
                ${training ? formatTime(training?.dateTo) : ''} 
                `}
            </p>
    )
}

export default TitleClientListOnTrainingPage;