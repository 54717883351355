import { useEffect, useState } from "react";
import { getClientsByCompanyBranchId } from "./clients";
import { useUserContext } from "../../../context/userContext";
import { useStudio } from "../../../context/studioContext";


function useClientsAndEmployeesLogic() {
    const [list, setList] = useState([]);
    const [openedForm, setOpenedForm] = useState('nameForm');
    const [selectoptionsSearch, setSelectOptionsSearch] = useState('clients'); // clients, trainers, admins,
    const [isLoadingFill, setIsLdingFill] = useState(false);

    return {
        list,
        openedForm,
        setOpenedForm,
        selectoptionsSearch,
        setSelectOptionsSearch,
        isLoadingFill,
    };
}

export default useClientsAndEmployeesLogic;
