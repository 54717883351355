import { useState } from 'react';
import { useStudio } from '../../../../../../context/studioContext';
import apiTrainingSettings from '../../../../../../api/apiTrainingSettings';

function useLogicModalUpdateOrRemove({ handleClickOpenFormUpdate, closeForm, selectedTrainingSetting, handleSuccessRemove }) {
    const { studio } = useStudio();
    const [openedForm, setOpenedForm] = useState('formChoice')
    const [isLoading, setIsLoading] = useState(false);
    const [validationText, setValidationText] = useState();

    function clickOpenFormUpdate() {
        closeForm()
        handleClickOpenFormUpdate()
    }

    async function removeTrainingSettings() {
        try {
            setIsLoading(true)
            const data = {
                trainingSettingsId: selectedTrainingSetting._id,
                companyBranchId: studio._id,
            }
            await apiTrainingSettings.remove(data)
            handleSuccessRemove()
            setIsLoading(false)
            closeForm()
        }
        catch (err) {
            setValidationText(err.message)
            setIsLoading(false)
        }
    }

    return ({
        openedForm, setOpenedForm,
        isLoading,
        validationText, setValidationText,
        clickOpenFormUpdate,
        removeTrainingSettings,
    })
}

export default useLogicModalUpdateOrRemove;