import React from "react";
import ListWithOnClickElement from "../../../../commonComponents/pages/ListWithOnClickElement/ListWithOnClickElement";
import useLogicListActiveVisits from "./logic/useLogicListActiveVisits";
import TimeTableListElement from "../../../../commonComponents/pages/TimeTable/TimeTableListElement/TimeTableListElement";
import { useStudio } from "../../../../context/studioContext";

/** Компонент отобажения списка активных записей и истории посещений по абонементу на тренировку у клиента */
function ListVisits({
    client,
    groupeAbonement,
    personalAbonement,
    handleSelectTraining,
}) {
    const { objStyles } = useStudio();
    const {
        activeVisits,
        visits,
    } = useLogicListActiveVisits({ client, groupeAbonement, personalAbonement })

    return (
        <div>
            {activeVisits && activeVisits.length > 0 &&
                <>
                    <p className={`title margin-top__30px ${objStyles.colorText}`}>Активные записи</p>
                    <ListWithOnClickElement
                        array={activeVisits}
                        handleClickOnElement={(training) => { handleSelectTraining(training) }}
                        children={(training) => <TimeTableListElement training={training} />}
                    />
                </>
            }
            {visits && visits.length > 0 &&
                <>
                    <p className={`title margin-top__30px ${objStyles.colorText}`}>История посещений</p>
                    <ListWithOnClickElement
                        array={visits}
                        handleClickOnElement={(training) => { console.log() }}
                        disabledCick={false}
                        children={(training) => <TimeTableListElement training={training} />}
                    />
                </>
            }
        </div>
    )
}

export default ListVisits;