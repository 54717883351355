/**
 * Функция для подсчета уникальных сочетаний typeOfTraining.type и typeOfTraining.payment.
 * @param {Array} trainings - Массив объектов тренировок.
 * @returns {Array} - Массив объектов с информацией о каждом уникальном сочетании.
 */
const calculateUniqueCombinations = (trainings, paymentSettings) => {
    const combinations = {};

    trainings.forEach(training => {
        const type = training.typeOfTraining.type;
        let payment = 0;
        let typePayment = '';
        if (type.includes('групп')) {
            payment = paymentSettings.groupePayment;
        } else {
            payment = paymentSettings.personalPayment;
        }
        typePayment = paymentSettings.groupePaymentType
        const visitsCount = training.listOfVisits.length;

        const key = `${type}`;

        if (!combinations[key]) {
            combinations[key] = {
                type: type,
                typePayment,
                payment: payment,
                visits: 0,
                quantity: 0
            };
        }

        combinations[key].visits += visitsCount;
        combinations[key].quantity += 1;
    });

    return Object.values(combinations);
};

export {
    calculateUniqueCombinations,
}