import React, { useEffect, useState } from "react";
import MonthSelector from "../../selectors/MonthSelector/MonthSelector";
import YearSelector from "../../selectors/YearSelector/YearSelector";

/**
 * Компонент выбора месяца и года.
 * @param {Object} props - Свойства компонента.
 * @param {Function} props.handleDate - Функция обратного вызова для передачи выбранной даты.
 * @returns {JSX.Element} - Элемент React.
 */
function MonthAndYearSelector({ handleDate, isFullMonthsAndYears = false }) {
    const [month, setMonth] = useState();
    const [year, setYear] = useState();
    const months = [
        "январь",
        "февраль",
        "март",
        "апрель",
        "май",
        "июнь",
        "июль",
        "август",
        "сентябрь",
        "октябрь",
        "ноябрь",
        "декабрь"
    ];

    useEffect(() => {
        if (month && year) {
            const date = new Date(year, months.indexOf(month), 1);
            handleDate && handleDate(date);
        }
    }, [month, year])

    return (
        <div className="margin-top__30px month-and-year-selector">
            <MonthSelector isFullMonths={isFullMonthsAndYears} handleMonth={setMonth} />
            <YearSelector isFullYears={isFullMonthsAndYears} handleYear={setYear} />
        </div>
    )
}

export default MonthAndYearSelector;