import { useState, useEffect } from 'react';

function useLogicFormTrainig({
    closeForm,
    handleSuccessUpdate,
    selectedTraining,
}) {

    const [openedForm, setOpenedForm] = useState('FormChoiceActionClient') // название открытой формы

    function handleCreate(newAbonement) {

    }

    function clickCloseForm() {
        closeForm();
        setTimeout(() => {
            setOpenedForm('FormAddOrUpdateTraining')
        }, 1000);
    }

    function handleSuccessUpdateClient(updatedClient) {
        handleSuccessUpdate(updatedClient);
        clickCloseForm()
    }

    function handleAbonementRemove() {

    }

    //Управлять формами
    useEffect(() => {
        setOpenedForm(selectedTraining ? 'FormChoiceActionTraining' : "FormAddOrUpdateTraining")
    }, [selectedTraining])


    return {
        openedForm,
        setOpenedForm,
        //Функции
        handleCreate,
        handleSuccessUpdateClient,
        clickCloseForm,
        handleAbonementRemove,
    };
}

export default useLogicFormTrainig;
