import React from "react";

function AdminInfo({ admin, handleClick }) {
    return (
        <div className='client-info'>
            <p
                className={`client-info__text font-weight-bold`}
                onClick={handleClick ? () => handleClick(admin) : () => { }}>
                {admin.surname} {admin.name}11
            </p>
        </div>
    )
}

export default AdminInfo;