import { useEffect, useState } from "react";
import { sortByDate } from "../../../../../utils/date";
import apiNonMemberShipVisit from "../../../../../api/apiNonMemberShipVisit";
import { useStudio } from "../../../../../context/studioContext";

function useLogicListActiveVisits({ groupeAbonement, personalAbonement, client }) {

    const { studio } = useStudio();
    const [groupeVisits, setGroupeVisits] = useState([]);
    const [personalVisits, setPersonalVisits] = useState([]);
    const [activeVisits, setActiveVisits] = useState([]);
    const [visits, setVisits] = useState([]);

    async function getNonMembershipVisits() {
        try {
            console.log(client)
            const res = await apiNonMemberShipVisit.getClientVisitsByCompanyBranchId({ companyBranchId: studio._id, clientId: client._id })
            const nonMembershipVisits = res.nonMembershipVisits.map((visit) => { return visit.training });
            return nonMembershipVisits;
        }
        catch (err) {
            console.log(err);
        }
    }

    async function fill() {
        try {
            let list = []
            const nonMembershipVisits = await getNonMembershipVisits();
            if (nonMembershipVisits) {
                list = list.concat(nonMembershipVisits);
            }
            if (groupeAbonement) {
                list = list.concat(groupeAbonement.listOfVisits);
            }
            if (personalAbonement) {
                list = list.concat(personalAbonement.listOfVisits);
            }
            console.log(list);
            const filtredByActiveVisits = list.filter((visit) => { return new Date(visit.dateOn) > new Date() })
            const sortedActiveVisits = sortByDate(filtredByActiveVisits)
            setActiveVisits(sortedActiveVisits);
            const filtredByVisits = list.filter((visit) => { return new Date(visit.dateOn) < new Date() })
            const sortedVisits = sortByDate(filtredByVisits)
            setVisits(sortedVisits);
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fill()
    }, [groupeAbonement, personalAbonement])

    return ({
        activeVisits,
        visits,
    })
}
export default useLogicListActiveVisits;