import AdminInfo from './components/AdminInfo';
import ModalWithForms from './components/ModalWithForms/ModalWithForms';
import useLogicAdminPage from "./logic/useLogicAdminPage";

/** Страница тренера (отображение инф о тренере) */
function AdminPage({ }) {

    const {
        userData,
        admin,
        handleSuccessUpdate,
        openedForm, setOpenedForm,
        isLoading,
        openFormAdmin,
        openFormUpdateTranerPaymentSetting,
        closeModal,
    } = useLogicAdminPage();

    return (
        <section className="trainer-page">
            {admin &&
                <AdminInfo
                    admin={admin}
                    onClick={openFormAdmin}
                />}
            <ModalWithForms
                isOpen={openedForm === 'formAdmin'}
                closeForm={closeModal}
                handleSuccessUpdate={handleSuccessUpdate}
                admin={admin}
            />
        </section>
    )
}

export default AdminPage;