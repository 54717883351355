import { regexStrokeInput } from '../../../../../../utils/regex'

function validateForm(dataSetting) {
        // Регулярное выражение для проверки, что значение не пустое
        const notEmptyRegex = /\S+/;

        // Проверяем все значения ключей объекта
        for (const key in dataSetting) {
            if (!notEmptyRegex.test(dataSetting[key].toString())) {
                return false; // Если хоть одно значение пустое, возвращаем false
            }

            if (!regexStrokeInput.test(dataSetting[key])) {
                return false;
            }
        }
        return true; // Если все значения прошли проверку, возвращаем true
    }

export {
    validateForm,
}