import SearchForm from "../../commonComponents/search/SearchForm/SearchForm";
import ButtonWithBorder from "../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import React from "react";
import { useUserContext } from "../../context/userContext";
import Switcher from "./components/Switcher/Switcher";
import useClientsAndEmployeesLogic from "./logic/useClientsAndEmployeesLogic";
import ClientsList from "./components/List/ClientsList/ClientsList";
import TrainersList from "./components/List/TrainersList/TrainersList";
import AdminsList from "./components/List/AdminsList/AdminsList";

/** Компонент для работы со списком клиентов или сотрудников */
function ClientsAndEmployees() {
    const { userData } = useUserContext();
    const {
        selectoptionsSearch,
        setSelectOptionsSearch,
    } = useClientsAndEmployeesLogic(userData);

    return (
        <div className="clients-and-employees">
             {userData && userData.role === 'superAdmin' &&
                <Switcher
                    select={selectoptionsSearch}
                    handleSelect={setSelectOptionsSearch}
                />}
            {selectoptionsSearch === 'clients' && userData &&
                <ClientsList />}
            {selectoptionsSearch === 'trainers' && userData &&
                <TrainersList />
            }
            {selectoptionsSearch === 'admins' && userData &&
                <AdminsList />
            }
        </div>
    )
}

export default ClientsAndEmployees;