import { useEffect, useState } from "react";
import { useStudio } from "../../../../../../../../context/studioContext";
import apiTraining from "../../../../../../../../api/apiTraining";


function useLogicFormAddOrUpdateTraining({ selectedHall, handleSuccesCreateTraining, handleSuccesUpdateTraining, updatingTraining, trainingSettings }) {
    const { studio } = useStudio();
    // Стейты, в которых содержятся значения инпута и информационный текст
    const [nameTrain, setNameTrain] = useState('') // название тренировки
    const [trainer, setTrainer] = useState('') // тренер
    const [typeTrain, setTypeTrain] = useState('') // тип тренировки
    const [spaceId, setSpaceId] = useState('') // тип зала
    const [numberOfPeople, setNumberOfPeople] = useState('') // максимальное количество людей на тренировке
    const [repeatValue, setRepeatValue] = useState('') // количество повторов тренировок
    const [dateOn, setDateOn] = useState(''); // дата начала
    const [dateTo, setDateTo] = useState(''); // время начала

    const [optionsTrainingTypes, setOptionsTrainingTypes] = useState(''); 
    const [optionsSpaces, setOptionsSpaces] = useState([]);
    const [optionsRepeats, setOptionsRepeats] = useState([{ value: true, label: 'повторять' }, { value: false, label: 'не повторять' },]);
    const [validMessage, setValidMessage] = useState();
    const [isValidForm, setValidForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function fillTypesSpace() {
        const halls = studio.spaces.map((space) => {
            return { value: space._id, label: space.name, capacity: space.capacity, }
        })
        setOptionsSpaces(halls)
    }
    useEffect(() => {
        fillTypesSpace()
    }, [])

    function selectCapacityFromSpace() {
        const findedSpace = optionsSpaces.find(space => space.value === spaceId)
        if (findedSpace) {
            setNumberOfPeople(findedSpace.capacity)
        }
    }
    useEffect(() => {
        selectCapacityFromSpace()
    }, [spaceId, optionsSpaces])

    useEffect(() => {
        if (selectedHall) {
            const findedHall = optionsSpaces.find(space => space.value === selectedHall)
            if (findedHall) {
                setSpaceId(findedHall.value)
            }
        }
    }, [selectedHall, optionsSpaces])

    function areStatesNotEmpty(...states) {
        return states.every(state => state !== '');
    }
    //Валидация
    useEffect(() => {
        const valid = areStatesNotEmpty(nameTrain,
            trainer,
            typeTrain,
            spaceId,
            numberOfPeople,
            repeatValue,
            dateOn,
            dateTo)
        setValidForm(valid);
    }, [nameTrain,
        trainer,
        typeTrain,
        spaceId,
        numberOfPeople,
        repeatValue,
        dateOn,
        dateTo])

    //Наполнть опции видов тренировок
    useEffect(()=>{
        if(trainingSettings){
            const options = trainingSettings.map((setting)=>{
                return {
                    value: setting._id,
                    label: setting.type
                }
            })
            setOptionsTrainingTypes(options)    
        }
    },[trainingSettings])

    //Наполнить если редатирование
    function fillUpdatingTraining() {
        setNameTrain(updatingTraining.name)
        setTrainer(updatingTraining.trainer._id)
        setDateOn(updatingTraining.dateOn)
        setDateTo(updatingTraining.dateTo)
        setNumberOfPeople(updatingTraining.capacity)
        setTypeTrain(updatingTraining.typeOfTraining)
        setRepeatValue(updatingTraining.repeat)
    }
    useEffect(() => {
        if (updatingTraining) {
            fillUpdatingTraining()
        }
    }, [updatingTraining])

    async function onSubmit() {
        try {
            const data = {
                name: nameTrain,
                trainerId: trainer,
                dateOn,
                dateTo,
                capacity: Number(numberOfPeople),
                typeOfTraining: typeTrain,
                repeat: repeatValue === 'true' ? true : false,
                companyBranchId: studio._id,
                hallId: spaceId,
            }
            setIsLoading(true);
            if (!updatingTraining) {
                await createTraining(data);
            } else {
                data.trainingId = updatingTraining._id;
                console.log(data);
                await updateTraining(data);
            }
            setIsLoading(false);
        }
        catch (err) {
            setValidMessage(err.message)
            setIsLoading(false);
        }
    }

    async function createTraining(data) {
        const res = await apiTraining.createTraining(data) // приходит массив созданных тренировок
        handleSuccesCreateTraining(res.trainings);
    }

    async function updateTraining(data) {
        const training = await apiTraining.updateTraining(data) // приходит массив созданных тренировок
        handleSuccesUpdateTraining(training);
    }

    return {
        nameTrain, setNameTrain,
        trainer, setTrainer,
        typeTrain, setTypeTrain,
        spaceId, setSpaceId, optionsSpaces,
        numberOfPeople, setNumberOfPeople,
        repeatValue, setRepeatValue, optionsRepeats,
        dateOn, setDateOn,
        dateTo, setDateTo,
        optionsTrainingTypes,
        validMessage, setValidMessage,
        isValidForm,
        onSubmit,
        isLoading,
    };
}

export default useLogicFormAddOrUpdateTraining;
