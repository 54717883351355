const messagesCalendar = {
    allDay: 'весь день',
    previous: 'предыдущий',
    next: 'следующий',
    today: 'сегодня',
    month: 'месяц',
    week: 'неделя',
    day: 'день',
    agenda: 'повестка дня',
    date: 'дата',
    time: 'время',
    event: 'событие',
};

// Получение текущей даты
const currentDate = new Date();

// Установка времени на 07:00
const minTime = new Date(currentDate);
minTime.setHours(7, 0, 0, 0);

// Установка времени на 23:59:59
const maxTime = new Date(currentDate);
maxTime.setHours(23, 59, 59, 999);

//Стиль ячеек
const getEventStyle = (event) => {
    const backgroundColor =
        event.title.includes('групп') ? 'gray' :
            event.title.includes('перс') ? 'green' :
                'blue';


    const style = {
        backgroundColor: backgroundColor,
    };

    return {
        style: style,
    };
};

/** Види тренировок */
const trainingTypes = [
    { value: 'группа', label: 'группа' },
    { value: 'перс', label: 'перс' },
    { value: 'самотрен', label: 'самотрен' },
    { value: 'аренда', label: 'аренда' },
]

export {
    messagesCalendar,
    minTime,
    maxTime,
    getEventStyle,
    trainingTypes,
}
