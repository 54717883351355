import React from "react"
import FormTwoTextButton from "../../../../../../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton"

function FormChoice({ addCurrent, addNew }) {
    return (
        <FormTwoTextButton
            title={'Добавить тренера'}
            nameButtonOne={'Существущий'}
            nameButtonTwo={'Новый'}
            clickButtonOne={addCurrent}
            clickButtonTwo={addNew}
        />
    )
}

export default FormChoice;