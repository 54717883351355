import React from "react";
import ModalWithChildren from "../../../../../../../../commonComponents/modal/ModalWithChildren";
import FormAddorUpdateTrainers from "./components/FormAddorUpdateTrainer/FormAddorUpdateTrainer";
import useFormAddTrainer from "./logic/useFormAddTrainer";
import FormChoice from "./components/FormChoice/FormChoice";
import FormAddCurrentTrainer from "./components/FormAddCurrentTrainer/FormAddCurrentTrainer";

function FormAddTrainer({
    isOpen,
    isClose,
    handleSuccessAdd,
}) {

    const {
        openedForm, setOpenedForm,
        closeForm,
    } = useFormAddTrainer({ isClose });

    return (
        <ModalWithChildren
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={closeForm} // клик на кнопку для сворачивания
            children={
                <>
                    {openedForm === 'FormChoice' &&
                        <FormChoice
                            addCurrent={() => setOpenedForm('FormAddCurrentTrainer')}
                            addNew={() => setOpenedForm('FormAddorUpdateTrainers')}
                        />}
                    {openedForm === 'FormAddorUpdateTrainers' &&
                        <FormAddorUpdateTrainers
                            textButtonSubmit={'Создать'}
                            handleSuccessAdd={handleSuccessAdd}
                        />
                    }
                    {openedForm === 'FormAddCurrentTrainer' &&
                        <FormAddCurrentTrainer
                            handleSuccessAdd={handleSuccessAdd}
                        />
                    }
                </>
            }
        />
    )
}

export default FormAddTrainer;