import React from "react";
import ModalWithChildren from "../../../../../commonComponents/modal/ModalWithChildren";
import CreateOrUpdateTrainingSettingsForm from "./components/CreateOrUpdateTrainingSettingsForm/CreateOrUpdateTrainingSettingsForm";

/** Модальное окно с формами создания или редактирования типа тренировки */
function CreateOrUpdateTrainingSettingsModalWithForm({ isOpen, handleSuccessCreate, handleSuccessUpdate, closeForm, selectedTrainingSetting }) {
    return (
        <ModalWithChildren
            isOpen={isOpen}
            isClose={closeForm}
            children={
                <CreateOrUpdateTrainingSettingsForm
                    handleSuccessCreate={handleSuccessCreate}
                    handleSuccessUpdate={handleSuccessUpdate}
                    selectedTrainingSetting={selectedTrainingSetting}
                />
            }
        />
    )
}

export default CreateOrUpdateTrainingSettingsModalWithForm