import { regexStrokeInput } from "../../../../../../../../../utils/regex";

function validateForm(dataSetting) {
    // Регулярное выражение для проверки, что значение не пустое
    const notEmptyRegex = /\S+/;

    // Регулярное выражение для проверки типа оплаты
    const typeRegex = regexStrokeInput;
    //const typeRegex = /^('групповые'|'персональные'|'настраиваемый-группы'|'настраиваемый-персы')$/;
    // Регулярное выражение для проверки стоимости
    const paymentRegex = /^[1-9]\d*$/;

    // Регулярное выражение для проверки названия
    const nameRegex = regexStrokeInput;

    // Проверяем все значения ключей объекта
    for (const key in dataSetting) {

        // Проверка на непустое значение
        if (key !== 'trainingTypes' && !notEmptyRegex.test(dataSetting[key])) {
            console.log(`Ошибка: поле "${key}" не должно быть пустым.`);
            return false; // Если хоть одно значение пустое, возвращаем false
        }

        // Дополнительные проверки для конкретных ключей
        // if (key === 'type' && !typeRegex.test(dataSetting[key])) {
        //     console.log(`Ошибка: поле "${key}" имеет неверное значение "${dataSetting[key]}" для типа.`);
        //     return false;
        // }

        if (key === 'payment' && !paymentRegex.test(dataSetting[key])) {
            console.log(`Ошибка: поле "${key}" имеет неверное значение "${dataSetting[key]}" для стоимости.`);
            return false;
        }

        if (key === 'name' && !nameRegex.test(dataSetting[key])) {
            console.log(`Ошибка: поле "${key}" имеет неверное значение "${dataSetting[key]}" для названия.`);
            return false;
        }
        // if (key === 'trainingTypes' && dataSetting[key].lenght < 1) {
        //     return false;
        // }

    }

    return true; // Если все значения прошли проверку, возвращаем true
}

export {
    validateForm,
}