import React from "react";
import FormTwoTextButton from "../../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton"
import useFormRemoveTrainerFromBranch from "./logic/useFormRemoveTrainerFromBranch";

function FormRemoveTrainerFromBranch({trainer, closeForm}) {

    const {
        removeTrainerFromBranch,
        isLoading,
        validateMessage, setValidateMessage,
    } = useFormRemoveTrainerFromBranch({trainer, closeForm});

    return (
        < FormTwoTextButton
            title={'Удалить тренера из филиала'}
            nameButtonOne={'Да'}
            nameButtonTwo={'Нет'}
            clickButtonOne={removeTrainerFromBranch}
            clickButtonTwo={closeForm}
            isLoading={isLoading}
            textForFill={validateMessage}
            cleanTextForFill={() => setValidateMessage()}
        />
    )
}

export default FormRemoveTrainerFromBranch;