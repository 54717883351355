import { useState, useEffect } from 'react';

function useLogicAbonement({
    client,
    isClose,
    abonement,
    handleSuccessSubmit,
}) {

    const [openedForm, setOpenedForm] = useState('FormChoiceAbonementCRUD') // название открытой формы

    function handleCreate(newAbonement) {
        abonement = newAbonement;
        clickCloseForm();
        if (abonement.type.trainingTypes.some((type) => type.type.includes('групп'))) {
            client.groupeAbonement = newAbonement;
        }
        if (abonement.type.trainingTypes.some((type) => type.type.includes('перс'))) {
            client.personalAbonement = newAbonement;
        }
    }

    function clickCloseForm() {
        isClose();
       // setTimeout(() => setOpenedForm(abonement ? 'FormChoiceAbonementCRUD' : 'FormCreateOrUpdateAbonement'), 1000)
    }

    function handleAbonementRemove() {
        clickCloseForm();
        // if (abonement.type.includes('группа')) {
        if (abonement.type.trainingTypes.some((type) => type.type.includes('групп'))) {
            client.groupeAbonement = null;
        }
        if (abonement.type.trainingTypes.some((type) => type.type.includes('перс'))) {
            client.personalAbonement = null;
        }
        abonement = null;
    }

    useEffect(() => {
        setOpenedForm(abonement ? 'FormChoiceAbonementCRUD' : 'FormCreateOrUpdateAbonement')
    }, [abonement])


    return {
        openedForm,
        setOpenedForm,
        //Функции
        handleCreate,
        clickCloseForm,
        handleAbonementRemove,
    };
}

export default useLogicAbonement;
