import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import apiTraining from "../../../api/apiTraining";
import { sortBySurname } from "../../../utils/listUtils";
import { useStudio } from "../../../context/studioContext";


function useLogicClientListOnTrainingPage() {
    const { studio } = useStudio();
    const location = useLocation();
    const [training, setTaining] = useState();
    //Клиенты
    const [clientsOnTraining, setClientsOnTraining] = useState([]);
    const [selectedClient, setSelectedClient] = useState();
    //Лоадер
    const [isLoadingFill, setIsLdingFill] = useState(false);
    // Формы
    const [openedForm, setOpenedForm] = useState('');
    const [validMessage, setValidMessage] = useState(undefined);

    function handleClickOnClient(client) {
        setSelectedClient(client);
        setOpenedForm('ModalAddClientOnTraining')
    }

    function handleSuccessAddVisit(client) {
        const updatedList = [...clientsOnTraining, client]
        setClientsOnTraining(updatedList)
        setOpenedForm('')
    }

    function handleSuccessRemoveVisit() {
        const filtredList = clientsOnTraining.filter(client => client._id.toString() !== selectedClient._id.toString())
        setClientsOnTraining(filtredList)
        setOpenedForm('')
        setSelectedClient()
    }

    useEffect(() => {
        if (location.state) {
            setTaining(location.state);
        }
    }, [location])

    async function fill() {
        try {
            setValidMessage(undefined);
            setIsLdingFill(true);
            const data = await apiTraining.getClientsToTrainingVisitList(training._id, studio._id)
            const clients = sortBySurname(data.clients)
            setClientsOnTraining(clients);
            setIsLdingFill(false);
        }
        catch (err) {
            setIsLdingFill(false);
            setValidMessage(err.message);
        }
    }
    useEffect(() => {
        if (training && studio) {
            fill()
        }
    }, [training, studio])

    return ({
        handleClickOnClient,
        handleSuccessAddVisit,
        handleSuccessRemoveVisit,
        training,
        isLoadingFill,
        clientsOnTraining,
        selectedClient,
        openedForm, setOpenedForm,
        validMessage,
    })
}

export default useLogicClientListOnTrainingPage;