import React from "react";
import InputSelect from "../../../../../commonComponents/inputs/InputSelect/InputSelect";
import InputText from "../../../../../commonComponents/inputs/InputText/InputText";
import useLogicFormPaymentSetting from "./logic/useLogicFormPaymentSetting";

function FormPaymentSetting({ handleDataPayment, handleFormValidate, paymentSettings }) {

    const {
        dataPayment, setDataPayment,
    } = useLogicFormPaymentSetting({ handleDataPayment, handleFormValidate, paymentSettings });

    return (
        <>
            <p className="title margin-top__30px">Оплата тренеру</p>
            <InputSelect
                label={'тип'}
                options={[{ value: 'client', label: "за клиента" }, { value: 'training', label: "за тренировку" }, { value: 'procent', label: "процент" }]}
                value={dataPayment.groupePaymentType}
                onChange={(value) => setDataPayment((prev) => ({ ...prev, trainingSettingPaymentType: value }))}
                placeholder={'Выберите тип'}
                isRequired={true}
            />
            {dataPayment.groupePaymentType !== 'procent' ?
                <InputText
                    inputValue={dataPayment.groupePayment}
                    setInputChange={(value) => setDataPayment((prev) => ({ ...prev, trainingSettingPayment: value }))}
                    placeholder={"Введите оплату"}
                    //bottomText={validationMassegeEmail}
                    label={"руб"}
                    type={'number'}
                    isRequired={false}
                />
                :
                <InputText
                    inputValue={dataPayment.groupePaymentType}
                    setInputChange={(value) => setDataPayment((prev) => ({ ...prev, groupePaymentType: value }))}
                    placeholder={"Введите %"}
                    //bottomText={validationMassegeEmail}
                    label={'%'}
                    type={'number'}
                    isRequired={true}
                />}
            {/* <InputRangeSlider /> */}
        </>
    )
}

export default FormPaymentSetting;