const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.(ru|рф)$/;
const regexEmailEn = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+(\.[a-zA-Z0-9-_.]+|\.[а-яА-ЯёЁ0-9-_.]+)$/;
const regexPassword = /^[a-zA-Z0-9]+$/;
const regexVerificationCode = /^[a-zA-Z0-9]+$/;
const regexLogin = /^[a-zA-Zа-яА-Я0-9_\-.,]+$/u;
const regexStrokeInput = /^[a-zA-Zа-яА-Я0-9\s.,()-]*$/;
const regexNumericInput = /^[0-9.,]+$/;
const regexVIN = /^[A-HJ-NPR-Z0-9]{17}$/i;



export { regexEmail, regexPassword, regexVerificationCode, regexLogin, regexStrokeInput, regexNumericInput, regexVIN, regexEmailEn}