/**
 * Удаляет все символы кроме цифр в строке и добавляет "7" в начало строки,
 * если количество символов строки меньше или равно 11.
 * @param {string} str - Входная строка.
 * @returns {string} Отформатированная строка.
 */
function formatPhoneNumber(str) {
    // Удаляем все символы кроме цифр
    const digitsOnly = str.replace(/\D/g, '');
    if (digitsOnly.length > 10) {
        // Удаляем одиннадцатый символ, используя методы slice для получения подстроки до 10 символа и после 11 символа
        return digitsOnly.slice(0, 9) + digitsOnly.slice(10);
    } else {
        // Если длина строки меньше или равна 10, возвращаем исходную строку без изменений
        return digitsOnly;
    }
}
export {
    formatPhoneNumber,
}