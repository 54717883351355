import LoaderAbovePage from "../../commonComponents/loaders/LoaderAbovePage/LoaderAbovePage";
import MonthAndYearSelector from "../../commonComponents/pages/MonthAndYearSelector/MonthAndYearSelector";
import TrainerInfo from "./components/TrainerInfo/TrainerInfo";
import TrainerStatistics from "./components/TrainerStatistics/TrainerStatistics";
import ModalTrainerForms from "./components/modalAndForms/ModalTrainerForms/ModalTrainerForms";
import useLogicTrainerPage from "./logic/useLogicTrainerPage";
import TrainerPaymentSettings from "./components/TrainerPaymentSettings/TrainerPaymentSettings";

/** Страница тренера (отображение инф о тренере) */
function TrainerPage({ }) {

    const {
        userData,
        trainer,
        handleSuccessUpdate,
        setSelectedDate,
        openedForm, setOpenedForm,
        rangeStatisticDate,
        isLoading,
        openFormTrainer,
        openFormUpdateTranerPaymentSetting,
        closeModal,
    } = useLogicTrainerPage();

    return (
        <section className="trainer-page">
            {!isLoading && trainer ?
                <>
                    <TrainerInfo
                        trainer={trainer}
                        onClick={openFormTrainer}
                    />
                    <TrainerPaymentSettings
                        paymentSetting={trainer.paymenSettings}
                        onClick={openFormUpdateTranerPaymentSetting}
                    />
                    {['superAdmin', 'trainer'].includes(userData.role) &&
                        <>
                            <MonthAndYearSelector
                                handleDate={setSelectedDate}
                                isFullMonthsAndYears={userData && userData.role === 'superAdmin'}
                            />
                            <TrainerStatistics
                                dateOn={rangeStatisticDate.dateOn}
                                dateTo={rangeStatisticDate.dateTo}
                                trainerId={trainer?._id}
                            />
                        </>
                    }
                    <ModalTrainerForms
                        trainer={trainer}
                        handleSuccessUpdate={handleSuccessUpdate}
                        isOpen={openedForm}
                        closeForm={closeModal}
                    />
                </>
                : <LoaderAbovePage
                    isOpen={isLoading}
                />}
        </section>
    )
}

export default TrainerPage;