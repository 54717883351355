import ButtonWithBorder from "../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import FormTwoTextButton from "../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton";

/** Форма выбора добавления клиента по имени или по QR */
function FormChoiceNameOrQR({ clickName, clcikQR }) {
    return (
        <FormTwoTextButton
            title={'Добавить'}
            nameButtonOne={'По ФИО или Тел'}
            clickButtonOne={clickName}
            nameButtonTwo={'По QR'}
            clickButtonTwo={clcikQR}
        />
    )
}

export default FormChoiceNameOrQR;