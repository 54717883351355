import React from 'react';

/**
 * Компонент для отображения выпадающего списка (select) с опциями.
 * @param {Object} props - Свойства компонента.
 * @param {Array} props.options - Массив объектов опций для списка. Каждая опция должна содержать свойства `value` и `label`.
 * @param {string} props.value - Значение выбранной опции.
 * @param {function} props.onChange - Обработчик события изменения выбранной опции.
 * @param {string} props.colorBackground - Цвет фона компонента.
 * @param {string} props.bottomText - Сообщение об ошибке валидации.
 * @param {string} props.placeholder - Сообщение об ошибке валидации.
  * @param {string} [props.isRequired=true] - обязательно или нет к заполнению
 * @returns {JSX.Element} Компонент InputSelect.
 */
function InputSelect({
    options,
    value,
    onChange,
    bottomText,
    label,
    placeholder,
    isRequired,
    isMultiple = false,
}) {
    function handleInputChange(e) {
        onChange(e.target.value);
    }

    return (
        <div className={`input-select__container ${label ? 'input-select__container_flex-row' : ''}`}>
            {label &&
                <p className='input-select__label'>{label}</p>}
            <select
                value={value}
                onChange={handleInputChange}
                className={`input-select`}
                required={isRequired}
                multiple={isMultiple}
            >
                {placeholder &&
                    <option className="input-select__option" value="">{placeholder}</option>}
                {options && options.map(option => (
                    <option key={option.value} className="input-select__option" value={option.value}>{option.label}</option>
                ))}
            </select>
            <span className="input__text-error" id="error-typeoftrainings">{bottomText}</span>
        </div>
    );
}

export default InputSelect;
