import { useEffect, useState } from "react";
import ChoiserStudio from "../choiserStudio/ChoiserStudio";
import { getCompanyBranchesAsyncLocalStorage } from "../../../utils/localStorage/studioChoice";
import { useUserContext } from "../../../context/userContext";

/** Заголовок страницы */
function HeaderPage() {

    const { userData } = useUserContext();
    const [companyBranches, setCompanyBranches] = useState([]);
    
    async function fill() {
        const branches = await getCompanyBranchesAsyncLocalStorage();
        setCompanyBranches(branches);
    }
    useEffect(() => {
        fill()
    }, [])

    const namesRoles = {
        superAdmin: 'SUPERADMIN',
        admin: 'ADMIN',
        trainer: 'TRAINER',
    }

    return (
        <header className={`header-page`}>
            <ChoiserStudio whiteFlex={true} companyBranches={companyBranches} />
            {userData &&
                <span className="header-page__role">{namesRoles[userData.role]}</span>}
        </header>
    )
}

export default HeaderPage;