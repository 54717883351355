import React from "react";
import useLogicNonMembershipVisit from "./logic/useLogicNonMembershipVisit";
import LoaderAbovePage from "../../commonComponents/loaders/LoaderAbovePage/LoaderAbovePage";
import Preloader from "../../commonComponents/loaders/Preloader/Preloader";
import NonMembershipsVisits from "./components/NonMembershipsVisits/NonMembershipsVisits";
import NonMembershipVisitsModalAndForms from "./components/modalsAndForms/NonMembershipVisitsModalAndForms";

/** Страница управления посещениями без абонементов */
const NonMembershipVisitPage = () => {

    const {
        selectedVisit,
        loading,
        nonMemebershipVisits,
        isOpenForm, setIsOpenForm,
        clickOnVisit,
        handleSuccessConfirm,
        handleRemove,
    } = useLogicNonMembershipVisit();

    return (
        <div className="non-membership-visits-page">
            <NonMembershipsVisits
                visits={nonMemebershipVisits}
                isLoading={loading === 'fill'}
                handleClickOnVisit={clickOnVisit}
            />
            <NonMembershipVisitsModalAndForms
                isOpen={isOpenForm}
                closeModal={() => setIsOpenForm(false)}
                visit={selectedVisit}
                handleSuccessConfirm={handleSuccessConfirm}
                handleRemove={handleRemove}
            />
        </div>
    )
}

export default NonMembershipVisitPage;