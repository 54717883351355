import React from "react";
import MainRoutes from "../../Routes/MainRoutes";
import LoaderPage from "../../commonComponents/LoaderPage/LoaderPage";
import NavTab from "../../commonComponents/NavTab/NavTab";
import HeaderPage from "../../commonComponents/pages/header/HeaderPage";
import { useStudio } from "../../context/studioContext";
import { useUserContext } from "../../context/userContext";
import Login from "../../commonComponents/Login/Login";
import ChoiserStudioPage from "../ChoiserStudioPage/ChoiserStudioPage";
import { scrollControllNavTab } from "../../commonComponents/NavTab/utils/functions";

/** Страница суперадмина - все права для работы со студией */
function MainPage() {
    const { authLoading, userData } = useUserContext();
    const { studioLoading, studio } = useStudio();

    // scrollControllNavTab();

    return (
        <>
            {authLoading || studioLoading ?
                <LoaderPage />
                : <></>}

            {!userData && !authLoading &&
                <Login />
            }

            {userData && !studio && !studioLoading &&
                < ChoiserStudioPage />
            }

            {!authLoading && !studioLoading && userData && studio &&
                <HeaderPage />
            }
            {!authLoading && !studioLoading && userData && studio &&
                <MainRoutes />
            }
            {userData && studio &&
                <NavTab />
            }
        </>
    )
}

export default MainPage;