import React from 'react';
import ClientInfo from '../../commonComponents/pages/ClientInfo/ClientInfo';
import FormAbonement from './components/forms/FormAbonement/FormAbonement';
import FormClient from './components/forms/FormClient/FormClient';
import FormRemoveVisitWithModal from './components/forms/FormRemoveVisitWithModal/FormRemoveVisitWithModal';
import ListVisits from './components/ListActiveVisits/ListVisits';
import Preloader from '../../commonComponents/loaders/Preloader/Preloader';
import useLogicClientPage from './logic/useLogicClientPage';
import ListClentAbonements from './components/ListClentAbonements/ListClentAbonements';

/** Страница оображения информации о клиенте и его абонементе и посещениях */
function ClientPage() {
    const {
        client,
        openedForm, setOpenedForm,
        selectedAbonement,
        abonementType,
        abonementSettigsInputOptions,
        groupeAbonement,
        personalAbonement,
        selectedTraining,
        openAbonement,
        abonements,
        handleSelectTraining,
        handleSuccessUpdate,
        handleSuccessRemoveVisit,
        isLoading,
        validMessage,
        closeFormAbonement,
    } = useLogicClientPage();

    return (
        <section className='client-page'>
            {client &&
                <ClientInfo
                    client={client}
                    handleClickOnAbonement={openAbonement}
                    handleClickOnClient={() => setOpenedForm('FormClient')}
                />}
            <div className='margin-top__30px'>
                {!isLoading ?
                    <ListVisits
                        client={client}
                        groupeAbonement={groupeAbonement}
                        personalAbonement={personalAbonement}
                        handleSelectTraining={handleSelectTraining}
                    />
                    : <Preloader />}
                {validMessage &&
                    <p>{validMessage}</p>}
            </div>
            {abonements && abonements.length > 0 &&
                <div className='margin-top__30px'>
                    <ListClentAbonements abonements={abonements} />
                </div>}
            {client &&
                <FormAbonement
                    type={abonementType}
                    isUpdate={false}
                    isOpen={openedForm === 'FormAbonement'}
                    isClose={closeFormAbonement}
                    abonement={selectedAbonement} // если передан - то редактирование 
                    abonementSettigsInputOptions={abonementSettigsInputOptions}
                    client={client}
                />}
            {client &&
                <FormClient
                    isOpen={openedForm === 'FormClient'}
                    closeForm={() => setOpenedForm()}
                    client={client}
                    handleSuccessUpdate={handleSuccessUpdate}
                />}
            {client &&
                <FormRemoveVisitWithModal
                    isOpen={openedForm === 'FormRemoveVisit'}
                    closeForm={() => setOpenedForm()}
                    client={client}
                    training={selectedTraining}
                    handleSuccessRemoveVisit={handleSuccessRemoveVisit}
                />
            }
        </section>
    )
}

export default ClientPage;