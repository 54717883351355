import { useState } from "react";

function useForm({
    setAbonementType,
    setSelectedAbonement,
}){

    const [openedForm, setOpenedForm] = useState();

    function openAbonement(type, abonement) {
        setAbonementType(type)
        setSelectedAbonement(abonement)
        const nameForm = type.includes('группа') ? 'FormAbonement' : 'FormAbonement'
        setOpenedForm(nameForm)
    }

    return({
        openedForm, setOpenedForm,
        openAbonement,
    })
}

export default useForm;