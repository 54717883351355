import ButtonWithBorder from "../../../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";

/** Форма для выбора работы с абонементом */
function FormChoiceAbonementCRUD({
    abonement,
    openCreateForm,
    openUpdateForm,
    openDeleteForm,
}) {
    return (
        <>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Новый'} onClick={openCreateForm} />
            </div>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Редактировать'} onClick={openUpdateForm} />
            </div>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Удалить'} onClick={openDeleteForm} />
            </div>
        </>
    )
}

export default FormChoiceAbonementCRUD;