import { useState } from "react";
import apiClient from "../../../../../../../api/apiClient";
import { useStudio } from "../../../../../../../context/studioContext";

function useLogicFormRefreshPassword({ client }) {
    const { studio } = useStudio();
    const [loading, setLoading] = useState(false);
    const [validateMessage, setValidateMessage] = useState();

    function clearValidateMessage() {
        setValidateMessage()
    }

    async function refreshPassword() {
        try {
            setLoading(true);
            apiClient.refreshPasswordClient(client._id, studio._id);
            setLoading(false);
            setValidateMessage('Пароль отправлен')
        }
        catch (err) {
            setLoading(false);
            setValidateMessage(err.message)
        }
    }

    return ({
        loading,
        validateMessage, clearValidateMessage,
        refreshPassword,
    })
}

export default useLogicFormRefreshPassword;