import { useState } from 'react';
import { getDateAddMonthAndOneDay } from './utils/date';
import { useStudio } from '../../../../../../../../context/studioContext';
import apiAbonement from '../../../../../../../../api/apiAbonement';
import useEfects from "./hooks/useEffects";

function useLogicFormCreateOrUpdateAbonement({
    client,
    abonement,
    handleCreate,
    abonementSettigsInputOptions,
}) {
    const { studio } = useStudio();
    // Стейты, в которых содержятся значения инпута и информационный текст
    const [abonementType, setAbonementType] = useState();
    const [dateOn, setDateOn] = useState(new Date());
    const [dateTo, setDateTo] = useState(getDateAddMonthAndOneDay(new Date()));
    const [quantity, setQuantity] = useState('1'); // количество тренировок
    const [cost, setCost] = useState('0'); // стоимость абонемента
    //Лоадер
    const [isLoading, setIsLoading] = useState(false);
    // Валидация
    const [isValidForm, setIsValidForm] = useState(false);
    const [submitText, setSubmitText] = useState();
    const [isDisabledInputs, setIsDisabledInputs] = useState(false);

    async function onSubmit() {
        try {
            setIsLoading(true);
            const data = {
                companyBranchId: studio._id,
                clientId: client._id,
                dateOn: new Date(dateOn).toISOString(),
                dateTo: new Date(dateTo).toISOString(),
                quantityVisitsOn: Number(quantity),
                type: abonementType,
                cost: Number(cost),
            }
            let newOrUpdatedAbonement = {};
            if (abonement) {
                data.abonementId = abonement._id;
                newOrUpdatedAbonement = await apiAbonement.updateAbonement(data);
            } else {
                newOrUpdatedAbonement = await apiAbonement.createAbonement(data);
            }
            handleCreate(newOrUpdatedAbonement)
            setIsLoading(false);
        }
        catch (err) {
            console.log(err);
            setSubmitText(err.message);
            setIsLoading(false);
        }

    }

    useEfects({
        abonement,
        setDateOn,
        setDateTo,
        setIsValidForm,
        abonementType, dateOn, dateTo, quantity, cost,
        abonementSettigsInputOptions,
        setQuantity,
        setCost,
        setAbonementType,
        setIsDisabledInputs,
    })


    return {
        abonementType,
        setAbonementType,
        dateOn,
        setDateOn,
        dateTo,
        setDateTo,
        quantity,
        setQuantity,
        cost,
        setCost,
        isValidForm,
        submitText,
        setSubmitText,
        isLoading,
        isDisabledInputs,
        //Функции
        onSubmit,
    };
}

export default useLogicFormCreateOrUpdateAbonement;
