import React from "react";
import { useStudio } from "../../../../../../context/studioContext";

/** Элемент списка настроек абонементов */
function SettingElementList({ element }) {
    const { objStyles } = useStudio();
   
    let trainings = '';
    element.trainingTypes.forEach((type, index) => { trainings += `${trainings} ${index > 1 ? ', ' : ''} ${type.type} ${type.customName ? type.customName : ''}` })
    return (
        <div className='width_100procents display-flex flex-direction_column'>
            {/* <div className='display-flex justify-content_space-between'>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    Тип
                </p>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    {element.type}
                </p>
            </div> */}
            <div className='display-flex justify-content_space-between'>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    Тренировки
                </p>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    {trainings}
                </p>
            </div>
            <div className='display-flex justify-content_space-between'>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    Название
                </p>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    {element.customName}
                </p>
            </div>
            <div className='display-flex justify-content_space-between'>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    Визиты
                </p>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    {element.quantityVisits}
                </p>
            </div>
            {/* <div className='display-flex justify-content_space-between'>
                <p className={`client-info__text font-weight-bold`}>
                    Тренировки
                </p>
                <p className={`client-info__text font-weight-bold`}>
                    {trainings}
                </p>
            </div> */}
            <div className='display-flex justify-content_space-between'>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    Руб
                </p>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    {element.cost}
                </p>
            </div>
        </div>
    )
}
export default SettingElementList;