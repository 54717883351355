import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../../../context/userContext";

function useLogicAdminPage() {
    const { userData } = useUserContext();
    const location = useLocation();
    const [admin, setAdmin] = useState();
    const [openedForm, setOpenedForm] = useState();
    const [isLoading, setIsLoading] = useState(false);

    // Управление формами
    function openFormAdmin() {
        if (userData.role === 'superAdmin') {
            setOpenedForm('formAdmin')
        }
    }

    function openFormUpdateTranerPaymentSetting() {
        if (userData.role === 'superAdmin') {
            setOpenedForm('FormUpdateTranerPaymentSetting')
        }
    }

    function closeModal() {
        setOpenedForm('')
    }

    function handleSuccessUpdate(admin) {
        setAdmin(admin);
        setOpenedForm()
    }

    async function fillAdmin() {
        try {
            setIsLoading(true);
            if (location.state) {
                setAdmin(location.state);
            }
            setIsLoading(false);
        }
        catch (err) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fillAdmin()
    }, [location])

    return ({
        userData,
        admin,
        handleSuccessUpdate,
        openedForm, setOpenedForm,
        isLoading,
        openFormAdmin,
        openFormUpdateTranerPaymentSetting,
        closeModal,
    })
}

export default useLogicAdminPage;