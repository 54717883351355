import { useEffect, useState } from "react";
import { updateObjectInArray, validateForm } from "./utils";
import apiAbonementSettings from "../../../../../../../../../api/apiAbonementSettings";
import { useStudio } from "../../../../../../../../../context/studioContext";

function useLogicCreateOrUpdateAbonementSettingsForm({
    trainingSettings,
    abonementSettings,
    handleSuccessCreate,
    handleSuccessUpdate,
}) {
    const { studio } = useStudio();
    const [dataSetting, setDataSetting] = useState({
        companyBranchId: studio._id,
        type: undefined, // групповые или персональные
        customName: '', // название
        cost: '', // стоимость
        quantityVisits: '', // количество визитов
        trainingTypes: [], // тип тренировок
    });
    const [optionTrainingSettings, setOptionTrainingSettings] = useState([])
    const [isValidForm, setIsValidForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [dataPayment, setDataPayment] = useState();

    async function create() {
        try {
            setIsLoading(true);
            const data = await apiAbonementSettings.create(dataSetting);
            handleSuccessCreate(data);
            setIsLoading(false);
        } catch (err) {
            console.error("Ошибка при создании:", err);
            setValidationMessage(err.message);
            setIsLoading(false);
        }
    }

    async function update() {
        try {
            setIsLoading(true);
            let updateObj = { ...dataSetting }
            updateObj.abonementSettingsId = abonementSettings._id;
            const data = await apiAbonementSettings.update(updateObj);
            handleSuccessUpdate(data);
            setIsLoading(false);
        } catch (err) {
            console.error("Ошибка при обновлении:", err);
            setValidationMessage(err.message);
            setIsLoading(false);
        }
    }

    function changeSelectedTrainings(trainings) {
        setDataSetting((prev) => ({ ...prev, trainingTypes: trainings }))
    }

    //Наполнение опций по настройам тренировок
    function fillTrainingSettings() {
        let options = []
        trainingSettings.forEach((setting) => {
            const label = `${setting.type} ${setting.customName ? setting.customName : ''}`
            console.log(label)
            const option = {
                value: setting._id,
                label,
            }
            options.push(option)
        }
        )
        console.log(options)
        setOptionTrainingSettings(options)
    }
    useEffect(() => {
        if (trainingSettings) {
            fillTrainingSettings()
        }
    }, [trainingSettings])

    // Сброс 
    useEffect(() => {
        setDataSetting((prev) => ({ ...prev, trainingTypes: [] }))
    }, [dataSetting.type])

    // Наполнить при редактировании
    useEffect(() => {
        if (abonementSettings) {
            const obj = {
                companyBranchId: abonementSettings.companyBranch,
                type: abonementSettings.type,
                customName: abonementSettings.customName,
                cost: abonementSettings.cost,
                quantityVisits: abonementSettings.quantityVisits,
                trainingTypes: [],
            }
            setDataSetting(obj);
        }
    }, [abonementSettings])



    //Валидация
    useEffect(() => {
        const validate = validateForm(dataSetting);
        console.log(validate)
        //setIsValidForm(!validate);
    }, [dataSetting])

    return {
        dataSetting, setDataSetting,
        optionTrainingSettings,
        update,
        create,
        isValidForm, setIsValidForm,
        isLoading, setIsLoading,
        validationMessage, setValidationMessage,
        changeSelectedTrainings,
    };
}

export default useLogicCreateOrUpdateAbonementSettingsForm;
