import React from "react";
import SettingsList from "./components/SettingsList/SettingsList";
import ButtonWithBorder from "../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import Preloader from "../../commonComponents/loaders/Preloader/Preloader";
import useLogicTrainingSettingPage from "./logic/useLogicTrainingSettingPage";
import CreateOrUpdateTrainingSettingsModalWithForm from "./components/formsAndModals/CreateOrUpdateTrainingSettingsModalWithForm/CreateOrUpdateTrainingSettingsModalWithForm";
import ModalUpdateOrRemove from './components/formsAndModals/ModalUpdateOrRemove/ModalUpdateOrRemove'
import { useStudio } from "../../context/studioContext";

/** Страница настроек тренировок (типы, оплата тренеру за посещения) */
function TrainingSettingPage({ }) {
    const { objStyles } = useStudio();
    const {
        trainingSettings,
        notActualTrainingSettings,
        isLoading,
        openedForm, setOpenedForm,
        selectedTrainingSetting,
        handleSuccessCreate,
        handleSuccessUpdate,
        handleSuccessRemove,
        handleClickrainingSetting,
        closeForm,
    } = useLogicTrainingSettingPage();

    return (
        <section className="training-setting">
            <p className={`title ${objStyles.colorText}`}>Настройки типов тренировок</p>
            {!isLoading &&
                <div className="margin-top__30px">
                    <ButtonWithBorder
                        text={'Добавить тип тренировки'}
                        onClick={() => setOpenedForm('CreateOrUpdateTrainingSettingsModalWithForm')}
                    />
                </div>}
            {isLoading ?
                <div className="margin-top__30px">
                    <Preloader />
                </div>
                :
                <>
                    <SettingsList
                        array={trainingSettings ? trainingSettings : []}
                        handleClickElement={handleClickrainingSetting}
                    />
                    <>
                        <SettingsList
                            title={'Устаревшие'}
                            array={notActualTrainingSettings ? notActualTrainingSettings : []}
                            handleClickElement={() => { }}
                        />
                    </>
                </>
            }
            <CreateOrUpdateTrainingSettingsModalWithForm
                isOpen={openedForm === 'CreateOrUpdateTrainingSettingsModalWithForm'}
                selectedTrainingSetting={selectedTrainingSetting}
                handleSuccessCreate={handleSuccessCreate}
                handleSuccessUpdate={handleSuccessUpdate}
                closeForm={closeForm}
            />
            <ModalUpdateOrRemove
                isOpen={openedForm === 'FormUpdateOrRemove'}
                closeForm={closeForm}
                selectedTrainingSetting={selectedTrainingSetting}
                handleClickOpenFormUpdate={() => setOpenedForm('CreateOrUpdateTrainingSettingsModalWithForm')}
                handleSuccessRemove={handleSuccessRemove}
            />
        </section>
    )
}

export default TrainingSettingPage;