import React from "react"
import FormTwoTextButton from "../../../../../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton"

function FormChoice({ addCurrent, addNew }) {
    return (
        <FormTwoTextButton
            title={'Добавить админа'}
            nameButtonOne={'Существущий'}
            nameButtonTwo={'Новый'}
            clickButtonOne={addCurrent}
            clickButtonTwo={addNew}
        />
    )
}

export default FormChoice;