import { useStudio } from "../../../context/studioContext";


function ListWithOnClickElement({
    array = [],
    handleClickOnElement = () => { },
    disabledCick = false,
    children
}) {
    const { objStyles } = useStudio();
    function click(e, element) {
        e.preventDefault();
        handleClickOnElement(element)
    }

    return (
        < div className="list">
            {array.length > 0 ?
                array.map(client => {
                    return (
                        <button
                            className={`list__element ${objStyles.colorText}`}
                            id={client._id}
                            key={client._id}
                            onClick={(e) => click(e, client)} // клик 
                            disabled={disabledCick}
                        >
                            {children(client)}
                        </button>)
                })
                :
                <p className={`list__empty-info ${objStyles.colorText}`}>Список пуст</p>}
        </div>
    )
}

export default ListWithOnClickElement;