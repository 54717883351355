import React from "react";
import FormTwoTextButton from "../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton";
import { useNavigate } from "react-router-dom";

/** Страница выбора настроек абонементов и тренировок */
function SettingPage({ }) {

    const navigate = useNavigate();

    function navigateTrainingSetting() {
        navigate('/training-setting')
    }

    function navigateAbonementSetting() {
        navigate('/abonement-setting')
    }

    return (
        <div className="setting-page">
            <FormTwoTextButton
                title={'Настроить'}
                nameButtonOne={'Тренировки'}
                nameButtonTwo={'Абонементы'}
                clickButtonOne={navigateTrainingSetting}
                clickButtonTwo={navigateAbonementSetting}
            />
        </div>
    )
}

export default SettingPage;