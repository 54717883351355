import React from "react";
import ModalWithChildren from "../../../../../commonComponents/modal/ModalWithChildren";
import FormChoiceNameOrQR from "../FormChoiceNameOrQR/FormChoiceNameOrQR"
import useLogicModalAddClientOnTraining from "./logic/useLogicModalAddClientOnTraining";
import FormAddByNameOrPhone from "../FormAddByNameOrPhone/FormAddByNameOrPhone";
import FormRemoveVisit from "../FormRemoveVisit/FormRemoveVisit";
import FormNonMembershipVisit from "../FormNonMembershipVisit/FormNonMembershipVisit";

function ModalAddClientOnTraining({
    isOpen,
    isClose,
    training,
    client,
    handleSuccessAddVisit,
    handleSuccessRemoveVisit,
}) {

    const {
        openedForm, setOpenedForm,
        handleClose,
    } = useLogicModalAddClientOnTraining(isClose, client);

    return (
        <ModalWithChildren
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={handleClose} // клик на кнопку для сворачивания
            children={
                <>
                    {openedForm === 'FormChoiceNameOrQR' &&
                        <FormChoiceNameOrQR
                            clickName={() => setOpenedForm('FormAddByNameOrPhone')}
                            clcikQR={() => setOpenedForm('FormAddByNameOrPhone')}
                        />
                    }
                    {openedForm === 'FormAddByNameOrPhone' && training &&
                        <FormAddByNameOrPhone
                            training={training}
                            handleSuccessAddVisit={handleSuccessAddVisit}
                            handleNonMembership={() => setOpenedForm('FormNonMembershipVisit')}
                        />
                    }
                    {openedForm === 'FormRemoveVisit' && client && training &&
                        <FormRemoveVisit
                            client={client}
                            training={training}
                            handleSuccessRemoveVisit={handleSuccessRemoveVisit}
                            closeForm={handleClose}
                        />
                    }
                </>
            }
        />
    )
}


export default ModalAddClientOnTraining;