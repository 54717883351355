import React from "react";
import InputSelect from "../../../../../../../../../../commonComponents/inputs/InputSelect/InputSelect";
import InputText from "../../../../../../../../../../commonComponents/inputs/InputText/InputText";
import useLogicFormPaymentSetting from "./logic/useLogicFormPaymentSetting";

function FormPaymentSetting({ handleDataPayment, handleFormValidate, paymentSettings }) {

    const {
        dataPayment, setDataPayment,
    } = useLogicFormPaymentSetting({ handleDataPayment, handleFormValidate, paymentSettings });

    return (
        <>
            <p className="title">Оплата за группы</p>
            <InputSelect
                label={'тип'}
                options={[{ value: 'client', label: "за клиента" }, { value: 'training', label: "за тренировку" }]}
                value={dataPayment.groupePaymentType}
                onChange={(value) => setDataPayment((prev) => ({ ...prev, groupePaymentType: value }))}
                placeholder={'Выберите тип'}
                isRequired={true}
            />
            <InputText
                inputValue={dataPayment.groupePayment}
                setInputChange={(value) => setDataPayment((prev) => ({ ...prev, groupePayment: value }))}
                placeholder={"Введите оплату"}
                //bottomText={validationMassegeEmail}
                label={"руб"}
                type={'number'}
                isRequired={false}
            />
            <p className="title">Оплата за персональные</p>
            <InputSelect
                label={'тип'}
                options={[{ value: 'procent', label: "процент" }, { value: 'training', label: "за тренировку" }]}
                value={dataPayment.personalPaymentType}
                onChange={(value) => setDataPayment((prev) => ({ ...prev, personalPaymentType: value }))}
                placeholder={'Выберите тип'}
                isRequired={true}
            />
            <InputText
                inputValue={dataPayment.personalPayment}
                setInputChange={(value) => setDataPayment((prev) => ({ ...prev, personalPayment: value }))}
                placeholder={"Введите %"}
                //bottomText={validationMassegeEmail}
                label={dataPayment.personalPaymentType === 'procent' ? '%' : 'руб'}
                type={'number'}
                isRequired={true}
            />
            {/* <InputRangeSlider /> */}
        </>
    )
}

export default FormPaymentSetting;