function sortByType(objectsArray) {
    // Используем метод sort() для сортировки массива по свойству type
    objectsArray.sort((a, b) => {
        // Приводим фамилии к нижнему регистру для корректного сравнения
        const typeA = a.customName.toLowerCase();
        const typeB = b.customName.toLowerCase();

        // Сравниваем фамилии объектов
        if (typeA < typeB) {
            return -1; // Если фамилия a предшествует фамилии b в алфавитном порядке
        }
        if (typeA > typeB) {
            return 1; // Если фамилия a следует после фамилии b в алфавитном порядке
        }
        return 0; // Если фамилии идентичны
    });

    // Возвращаем отсортированный массив
    return objectsArray;
}

function updateObjectInArray(array, newObject) {
    const index = array.findIndex(obj => obj._id === newObject._id);
    if (index !== -1) {
      array[index] = newObject;
    }
    return array;
  }

export {
    sortByType, 
    updateObjectInArray,
}