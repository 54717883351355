import { host } from "./const";

const apiAbonement = {
    host: host,

    createAbonement({
        companyBranchId,
        clientId, 
        dateOn,
        dateTo,
        quantityVisitsOn,
        type,
        cost,
    }) {
        return fetch(`${this.host}/abonement`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    companyBranchId,
                    clientId, 
                    dateOn,
                    dateTo,
                    quantityVisitsOn,
                    type,
                    cost,
                })
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    updateAbonement({
        abonementId,
        companyBranchId,
        clientId, 
        dateOn,
        dateTo,
        quantityVisitsOn,
        type,
        cost,
    }) {
        return fetch(`${this.host}/abonement`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    abonementId,
                    companyBranchId,
                    clientId, 
                    dateOn,
                    dateTo,
                    quantityVisitsOn,
                    type,
                    cost,
                })
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    removeAbonement(abonementId) {
        return fetch(`${this.host}/abonement`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    abonementId
                })
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },
}

export default apiAbonement;