import React from 'react';
import useLogicAdminsList from './logic/useLogicAdminsList';
import Loader from '../../../../../commonComponents/loaders/Preloader/Preloader';
import ButtonWithBorder from '../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder';
import SearchForm from '../../../../../commonComponents/search/SearchForm/SearchForm';
import ModalWithForm from './components/ModalWithForm/ModalWithForm';
import AdminInfo from './components/AdminInfo/AdminInfo';
import { useStudio } from '../../../../../context/studioContext';

/** Компонент работы со списком клиентов */
function AdminsList() {
    const { objStyles } = useStudio();
    const {
        list,
        openedForm,
        setOpenedForm,
        addAdmin,
        isLoadingFill,
        handleSubmOnSearch,
        navigateSelctedAdminPage,
        openModalWithForm,
        clickAdmin,
        selectedAdmin,
    } = useLogicAdminsList();

    return (
        <div className="list">
            <SearchForm handleSubmOnSearch={handleSubmOnSearch} />
            <div className='list__button-container'>
                <ButtonWithBorder
                    text={'Добавить +'}
                    onClick={openModalWithForm}
                />
            </div>
            {isLoadingFill ?
                <div className='margin-top__30px'>
                    <Loader />
                </div>
                :
                // {/* СПИСОК */ }
                < div className="list margin-top__30px">
                    {list !== undefined ?
                        list.map(admin => {

                            function click(e) {
                                navigateSelctedAdminPage(e, admin)
                            }

                            return (
                                <button
                                    className={`list__element ${objStyles.colorText}`}
                                    id={admin._id}
                                    key={admin._id}
                                    onClick={click}
                                >
                                    <AdminInfo
                                        admin={admin}
                                    />
                                </button>)
                        })
                        : <></>
                    }
                </div>}
            <ModalWithForm
                isOpen={openedForm === 'ModalWithForm'}
                isClose={() => { setOpenedForm('') }}
                textButtonSubmit={'Добавить'} // текст кнопки сабмита
                handleSuccessAdd={addAdmin}
            />
        </div >
    )
}



export default AdminsList;