import React from "react";
import { useStudio } from "../../../../../../context/studioContext";

function SettingElementList({ element }) {
    const { objStyles } = useStudio();

    return (
        <div className='width_100procents display-flex flex-direction_column'>
            <div className='display-flex justify-content_space-between'>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    Тип
                </p>
                <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                    {element.type}
                </p>
            </div>
            {element.customName &&
                <div className='display-flex justify-content_space-between'>
                    <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                        Название
                    </p>
                    <p className={`client-info__text font-weight-bold ${objStyles.colorText}`}>
                        {element.customName}
                    </p>
                </div>}
        </div>
    )
}
export default SettingElementList;