import React from "react";
import ListWithOnClickElement from "../../../../commonComponents/pages/ListWithOnClickElement/ListWithOnClickElement";
import SettingElementList from "./components/SettingElementList/SettingElementList";
import { useStudio } from "../../../../context/studioContext";

/** Компонент для отображения списка настроек по каждой тренировке */
function SettingsList({ array, handleClickElement, title }) {
    const { objStyles } = useStudio();
    return (
        <div className="margin-top__30px">
            {title &&
                <p className={`title font-weight-medium ${objStyles.colorText}`}>{title}</p>}
            <ListWithOnClickElement
                array={array}
                handleClickOnElement={handleClickElement}
                children={(item) => <SettingElementList element={item} />}
            />
        </div>
    )
}

export default SettingsList;