import { useEffect, useState } from "react";
import { formatPhoneNumber } from "./utils/functions";
import apiAdmin from "../../../../../../../../../../api/apiAdmin";
import { useUserContext } from "../../../../../../../../../../context/userContext";
import { useStudio } from "../../../../../../../../../../context/studioContext";

function useLogicFormAddorUpdateAdmins({ isOpen, handleSuccessAdd, handleSuccessUpdate, admin }) {
    const { userData } = useUserContext();
    const { studio } = useStudio();
    const [nameAdmin, setNameAdmin] = useState('Имя');
    const [subnameAdmin, setSubnameAdmin] = useState('Фамилия');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [dataAdmin, setDataAdmin] = useState({
        nameAdmin: 'Имя',
        subnameAdmin: 'Фамилия',
        number: '',
        email: 'email'
    })
    const [dataPayment, setDataPayment] = useState();
    const [validDataPayment, setValidDataPayment] = useState(false);
    const [validationMassegeEmail, setValidationMassegeEmail] = useState('Email');
    const [validationMassegeName, setValidationMassegeName] = useState('');
    const [validationMassegeSubname, setValidationMassegeSubname] = useState('');
    const [validationMassegeNumber, setValidationMassegeNumber] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isValidInputs, setIsValidInputs] = useState(false);
    const [isValidMessages, setIsValidMessages] = useState(false);
    const [validMessage, setValidMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    function handleChangeName(e) {
        setNameAdmin(e);
    }

    function handleChangeSubname(e) {
        setSubnameAdmin(e);
    }

    function handleChangeNumber(e) {
        let numberFromStr = formatPhoneNumber(e);
        setNumber(numberFromStr);
    }

    function handleChangeEmail(e) {
        setEmail(e);
    }

    async function handleSubmit() {
        try {
            setIsLoading(true);
            let data = {
                name: nameAdmin,
                surname: subnameAdmin,
                companyId: userData.company,
                companyBranchId : studio._id,
            }
            if (email && email !== "") {
                data.email = email;
            }
            if (number && number !== "") {
                data.phoneNumber = admin ? number : '7' + number;
            }

            if (admin) {
                data.adminId = admin._id;
                const updatedAdmin = await apiAdmin.updateAdmin(data)
                admin = updatedAdmin;
                handleSuccessUpdate(updatedAdmin);
            } else {
                const newAdmin = await apiAdmin.createAdmin(data)
                handleSuccessAdd(newAdmin);
            }

            setIsLoading(false);
            setValidMessage('');
        } catch (err) {
            setIsLoading(false);
            console.log(err);
            setValidMessage(err.message)
        }

    }

    useEffect(() => {
        setNameAdmin('');
        setSubnameAdmin('');
        setNumber('');
        setEmail('');
        setValidationMassegeName('Имя');
        setValidationMassegeSubname('Фамилия');
        setValidationMassegeNumber('Номер');
        setValidationMassegeEmail('Email');
    }, [isOpen]);

    useEffect(() => {
        if (nameAdmin === "" || subnameAdmin === "") {
            setIsValidInputs(false);
        } else {
            setIsValidInputs(true);
        }
    }, [nameAdmin, subnameAdmin, number, email]);

    useEffect(() => {
        if (
            validationMassegeEmail !== 'Email' ||
            validationMassegeName !== 'Имя' ||
            validationMassegeSubname !== 'Фамилия' ||
            validationMassegeNumber !== 'Номер'
        ) {
            setIsValidMessages(false);
        } else {
            setIsValidMessages(true);
        }
    }, [validationMassegeEmail, validationMassegeName, validationMassegeSubname, validationMassegeNumber]);

    useEffect(() => {

        if (isValidInputs && isValidMessages) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [isValidInputs, isValidMessages, validDataPayment]);

    //Если редактирование и есть админ
    useEffect(() => {
        if (admin) {
            setNameAdmin(admin.name);
            setSubnameAdmin(admin.surname);
            if (admin.contactData.phoneNumber) {
                setNumber(admin.contactData.phoneNumber)
            }
            if (admin.contactData.email) {
                setEmail(admin.contactData.email)
            }
        }
    }, [admin])

    return {
        nameAdmin,
        subnameAdmin,
        number,
        email,
        setDataPayment,
        setValidDataPayment,
        isSubmitDisabled,
        handleChangeName,
        handleChangeSubname,
        handleChangeNumber,
        handleChangeEmail,
        handleSubmit,
        isLoading,
        validMessage,
        setValidMessage
    };
}

export default useLogicFormAddorUpdateAdmins;
