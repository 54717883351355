import FormTwoTextButton from "../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton"
import useLogicFormRemoveVisit from "./logic/useLogicFormRemoveVisit"

/** Форма отмены визита на тренировку */
function FormRemoveVisit({ client, training, handleSuccessRemoveVisit, closeForm }) {

    const {
        removeVisit,
        isLoading,
        valdMessage, setValdMessage,
    }
        = useLogicFormRemoveVisit({ client, training, handleSuccessRemoveVisit });

    return (
        <FormTwoTextButton
            title={`Отменить запиcь ${client.name} ${client.surname}`}
            nameButtonOne={'Да'}
            nameButtonTwo={'Нет'}
            clickButtonOne={removeVisit}
            clickButtonTwo={closeForm}
            isLoading={isLoading}
            textForFill={valdMessage}
            cleanTextForFill={() => setValdMessage()}
        />
    )
}

export default FormRemoveVisit