import React, { useEffect, useState } from "react";

/** Выбор месяца */
function MonthSelector({ isFullMonths = true, handleMonth }) {
    const [month, setMonth] = useState('');
    const [monthNow, setMonthNow] = useState('');
    const [monthsOptions, setMonthsOptions] = useState([]); // опции в селект для выбора месяца
    const [monthPrevious, setMonthPrevious] = useState('');

    //Слушать изменение месяца
    function changeMonth(event) {
        let month = event.target.value
        setMonth(month);
        handleMonth && handleMonth(month)
    }

    // Установка текущего месяца и предыдущего месяца, а также года при загрузке
    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
        setMonth(currentMonth); // установить текущий месяц при загрузке
        setMonthNow(currentMonth); // Текущий месяц
        handleMonth && handleMonth(currentMonth)
        const previousMonth = new Date();
        previousMonth.setMonth(previousMonth.getMonth() - 1);
        const previousMonthName = previousMonth.toLocaleString('default', { month: 'long' });
        setMonthPrevious(previousMonthName); // предыдущий месяц
    }, [])

    //Наполнить опции для селекта выбора года и месяцев у суперадмина
    useEffect(() => {
        //Год
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        setMonth(currentMonth); // первоначально текущий месяц
        const options = [];
        for (let i = 0; i < 5; i++) {
            const yearValue = currentYear - i;
            options.push(
                <option key={yearValue} value={yearValue}>
                    {yearValue}
                </option>
            );
        }
        //месяц
        const optionsMonths = [];
        for (let i = 0; i < 12; i++) {
            const month = new Date();
            month.setMonth(month.getMonth() - i);
            const monthName = month.toLocaleString('default', { month: 'long' });
            optionsMonths.push(
                <option value={monthName} key={monthName}>
                    {monthName}
                </option>
            );
        }
        setMonthsOptions(optionsMonths);
    }, []);

    return (
        <div className="formadd__input-conainer">
            <select
                value={month}
                onChange={changeMonth}
                className={`formadd__input formadd__input_name_image formadd__input-select`}
            >
                {isFullMonths ?
                    monthsOptions
                    :
                    <>
                        <option value={monthNow}>{monthNow}</option>
                        <option value={monthPrevious}>{monthPrevious}</option>
                    </>
                }
            </select>
            <p className={`formadd__text-error`} id="error-firstname">Выбор месяца</p>
        </div>
    )
}

export default MonthSelector;