import { useEffect, useState } from "react";
import { getClientsByCompanyBranchId } from "../../../../logic/clients";
import { useUserContext } from "../../../../../../context/userContext";
import { useStudio } from "../../../../../../context/studioContext";
import { filterClientsByString } from "./functions";
import { sortBySurname } from "../../../../../../utils/listUtils";


function useLogicClientsList() {
    const { userData } = useUserContext();
    const { studio } = useStudio();
    const [initList, setInitList] = useState(undefined);
    const [list, setList] = useState([]);
    const [openedForm, setOpenedForm] = useState('nameForm');
    const [selectoptionsSearch, setSelectOptionsSearch] = useState('clients'); // clients, trainers, admins,
    const [isLoadingFill, setIsLdingFill] = useState(false);

    function addClient(client) {
        const updatedList = [...list]
        updatedList.unshift(client);
        setList(updatedList);
        setOpenedForm();
    }

    async function fill() {
        try {
            setIsLdingFill(true);
            let data = [];
            data = await getClientsByCompanyBranchId(studio._id);
            const sortedBuSurname = sortBySurname(data.clients)
            setList(sortedBuSurname);
            setIsLdingFill(false);
        }
        catch (err) {
            setIsLdingFill(false);
        }
    }

    function handleSubmOnSearch(value) {
        if (!initList) {
            setInitList(list);
        }
        setTimeout(() => {
            if (initList) {
                const filtredClients = filterClientsByString(initList, value);
                setList(filtredClients);
                if (value === '') {
                    setList(initList)
                }
            }
        })
    }

    useEffect(() => {
        fill()
    }, [])

    return {
        list,
        openedForm,
        setOpenedForm,
        selectoptionsSearch,
        setSelectOptionsSearch,
        addClient,
        isLoadingFill,
        handleSubmOnSearch,
    };
}

export default useLogicClientsList;
