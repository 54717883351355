import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function useLogicModalTrainerForms({ closeForm }) {
    const [openedForm, setOpenedForm] = useState('FormChoiceTrainerActions');

    function clickCloseForm() {
        closeForm();
        setTimeout(() => setOpenedForm('FormChoiceTrainerActions'), 1000)
    }

    return ({
        openedForm, setOpenedForm,
        clickCloseForm,
    })
}

export default useLogicModalTrainerForms;