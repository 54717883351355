import React from "react";
import { formatDateDDMMYY } from "../../../../utils/date";
import { useStudio } from "../../../../context/studioContext";

function AbonementInfo({ abonement }) {
    const { objStyles } = useStudio();
    return (
        <>
            {abonement ? (
                <>
                    <p className={`client-info__text font-weight-medium ${objStyles.colorText}`}>
                        осталось занятий {abonement.quantityVisits} из {abonement.quantityVisitsOn}
                    </p>
                    <p className={`client-info__text font-weight-medium ${objStyles.colorText}`}>
                        действует с {formatDateDDMMYY(new Date(abonement.dateOn))} по {formatDateDDMMYY(new Date(abonement.dateTo))}
                    </p>
                </>
            ) : (
                <p className={`client-info__text font-weight-medium ${objStyles.colorText}`}>абонемент отсутсвует</p>
            )}
        </>
    );
}

export default AbonementInfo;
