import { useEffect, useState } from "react";
import apiAdmin from "../../../../../../../../../../api/apiAdmin";
import { useStudio } from "../../../../../../../../../../context/studioContext";

function useLogicFormAddCurrentAdmin({ handleSuccessAdd }) {
    const { studio } = useStudio();
    const [admins, setAdmins] = useState([]);
    const [isLoaing, setIsLoaing] = useState(false);
    const [validMessage, setValidMessage] = useState();
    const [openedForm, setOpenedForm] = useState('')
    const [selectedAdmin, setSelectedAdmin] = useState();
    const [isLoadingAdmin, setIsLoadingAdmin] = useState(false);

    async function getAdmins() {
        try {
            setIsLoaing(true);
            const data = await apiAdmin.getAdminsByCompanyId(studio._id, studio.company)
            setAdmins(data.admins);
            setIsLoaing(false);
        }
        catch (err) {
            setIsLoaing(false);
            console.log(err)
            setValidMessage(err.message)
        }
    }

    function selectAdmin(data) {
        setSelectedAdmin(data);
        setOpenedForm('FormConfirm')
    }

    async function addAdmin() {
        try {
            setIsLoadingAdmin(true);
            const trainer = await apiAdmin.controlAdminCompanyBranch({ companyBranchId: studio._id, adminId: selectedAdmin._id, action: 'push' })
            setIsLoadingAdmin(false);
            handleSuccessAdd(trainer);
        }
        catch (err) {
            setIsLoadingAdmin(false);
            setValidMessage(err.message)
        }
    }

    useEffect(() => {
        if (studio) {
            getAdmins()
        }
    }, [studio])

    return ({
        admins,
        validMessage,
        isLoaing,
        selectAdmin,
        addAdmin,
        openedForm, setOpenedForm,
        selectedAdmin,
        isLoadingAdmin,
    })
}

export default useLogicFormAddCurrentAdmin;