import ModalWithChildren from "../../../../../commonComponents/modal/ModalWithChildren";
import FormRemoveVisit from "../../../../ClientListOnTrainingPage/components/formsAndModal/FormRemoveVisit/FormRemoveVisit";

/** Форма отмены визита клиена вместе с модальным окном */
function FormRemoveVisitWithModal({
    isOpen,
    closeForm,
    client,
    training,
    handleSuccessRemoveVisit,
}) {
    return (
        <ModalWithChildren
            isOpen={isOpen}
            isClose={closeForm}
            children={
                <FormRemoveVisit
                    client={client}
                    training={training}
                    handleSuccessRemoveVisit={handleSuccessRemoveVisit}
                    closeForm={closeForm}
                />
            }
        />
    )
}

export default FormRemoveVisitWithModal;