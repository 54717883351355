import React from "react";
import TitleClientListOnTrainingPage from "./components/TitleClientListOnTrainingPage";
import useLogicClientListOnTrainingPage from "./logic/useLogicClientListOnTrainingPage";
import ClientsListOnTraining from "./components/ClientsListOnTraining/ClientsListOnTraining";
import ButtonWithBorder from "../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import ModalAddClientOnTraining from './components/formsAndModal/ModalAddClientOnTraining/ModalAddClientOnTraining';

/** Список клиентов на тренировке */
function ClientListOnTrainingPage() {
    const {
        training,
        isLoadingFill,
        clientsOnTraining,
        selectedClient,
        openedForm, setOpenedForm,
        validMessage,
        handleClickOnClient,
        handleSuccessAddVisit,
        handleSuccessRemoveVisit,
    } = useLogicClientListOnTrainingPage();

    return (
        <div className="client-list-on-training-page width__100">
            <TitleClientListOnTrainingPage training={training} />
            <div className="margin-vertical__10px display-flex">
                <ButtonWithBorder
                    text={'Добавить'}
                    onClick={() => setOpenedForm('ModalAddClientOnTraining')}
                />
            </div>
            {!validMessage ?
                <ClientsListOnTraining
                    training={training}
                    clients={clientsOnTraining}
                    isLoading={isLoadingFill}
                    handleClickOnClient={handleClickOnClient}
                />
                : <p>{validMessage}</p>}
            <ModalAddClientOnTraining
                isOpen={openedForm === 'ModalAddClientOnTraining'}
                isClose={() => setOpenedForm('')}
                training={training}
                client={selectedClient}
                handleSuccessAddVisit={handleSuccessAddVisit}
                handleSuccessRemoveVisit={handleSuccessRemoveVisit}
            />
        </div>
    )
}

export default ClientListOnTrainingPage;