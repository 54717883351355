import { useEffect, useState } from "react";
import { useStudio } from "../../../context/studioContext";
import apiAbonementSettings from "../../../api/apiAbonementSettings";
import { sortByType, updateObjectInArray } from "./utils";
import apiTrainingSettings from "../../../api/apiTrainingSettings";

function useLogicAbonementSettingPage() {

    const { studio } = useStudio();
    const [abonementSettings, setAbonementSettings] = useState();
    const [trainingSettings, setTrainingSettings] = useState({ groupe: [], personal: [] });
    const [notActualAbonementSettings, setNotActualAbonementSettings] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [openedForm, setOpenedForm] = useState('nameForm');
    const [selectedAbonementSettings, setSelectedAbonementSettings] = useState();
    const [validateMessge, setValidateMessge] = useState();

    function handleClickAbonementSettings(data) {
        setSelectedAbonementSettings(data);
        setOpenedForm('FormChoice');
    }
    function handleSuccessCreate(data) {
        const updatedAbonementSettings = [...abonementSettings, data];
        setAbonementSettings(updatedAbonementSettings);
        setOpenedForm('nameForm');
    }

    function handleSuccessUpdate(data) {
        const updatedAbonementSettings = updateObjectInArray(abonementSettings, data);
        setAbonementSettings(updatedAbonementSettings);
        setOpenedForm('nameForm');
    }

    function handleSuccessRemove() {
        const updatedAbonementSettings = abonementSettings.filter((setting) => setting._id !== selectedAbonementSettings._id);
        setAbonementSettings(updatedAbonementSettings);
        setOpenedForm('nameForm');
    }

    async function getAbonementSettings() {
        try {
            setIsLoading(true);
            const data = await apiAbonementSettings.getAbonementSettingsByCompanyBranchId(studio._id);
            const sortedAbonementSettings = data?.abonementSettings?.length > 0 ? sortByType(data.abonementSettings) : [];
            setAbonementSettings(sortedAbonementSettings);
            setIsLoading(false);
        }
        catch (err) {
            console.log(err);
            setIsLoading(false);
            setValidateMessge(err.message)
        }
    }

    async function getTrainingSettings() {
        try {
            const data = await apiTrainingSettings.getTrainingSettingsByCompanyBranchId(studio._id)
            const actual = data.trainingSettings.filter(trainingSettings => trainingSettings.isActual)
            setTrainingSettings(actual);
        }
        catch (err) {

        }
    }

    function closeModal() {
        setOpenedForm();
        setSelectedAbonementSettings();
    }

    useEffect(() => {
        if (studio) {
            getAbonementSettings()
            getTrainingSettings()
        }
    }, [studio])

    return ({
        isLoading,
        openedForm, setOpenedForm,
        abonementSettings,
        notActualAbonementSettings,
        handleClickAbonementSettings,
        handleSuccessCreate,
        handleSuccessUpdate,
        handleSuccessRemove,
        validateMessge,
        trainingSettings,
        selectedAbonementSettings,
        closeModal,
    })
}

export default useLogicAbonementSettingPage;