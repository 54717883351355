import { useEffect, useState } from "react";
import { validateForm } from "./utils";

/**
 * Пользовательский хук для управления состоянием формы настроек оплаты.
 * @param {Object} params - Параметры для инициализации хука.
 * @returns {Object} - Объект, содержащий данные об оплате и функцию установки состояния.
 */
function useLogicFormPaymentSetting({ handleDataPayment, handleFormValidate, paymentSettings }) {
    const [dataPayment, setDataPayment] = useState({
        trainingSettingPaymentType: 'client',  // Тип оплаты для групповых занятий
        trainingSettingPayment: '', // Сумма оплаты для групповых занятий
    });

    useEffect(() => {
        handleDataPayment(dataPayment)
    }, [dataPayment])

    // Валидация
    useEffect(() => {
        const resultValidate = validateForm(dataPayment);
        handleFormValidate(resultValidate);
    }, [dataPayment])

    // Если передан для редактирования
    useEffect(() => {
        if (paymentSettings) {
            const obj = {
                paymentTrainerSettingId: paymentSettings._id,
                groupePaymentType: paymentSettings.groupePaymentType,
                groupePayment: paymentSettings.groupePayment,
                personalPaymentType: paymentSettings.personalPaymentType,
                personalPayment: paymentSettings.personalPayment,
            }
            setDataPayment(obj)
        }
    }, [paymentSettings])

    return {
        dataPayment,
        setDataPayment,
    };
}

export default useLogicFormPaymentSetting;
