function useHandleSuccess({ setClient, fill, setOpenedForm }) {

    function handleSuccessUpdate(updatedClient) {
        setClient(updatedClient);
        setOpenedForm();
    }

    function handleSuccessRemoveVisit() {
        fill();
        setOpenedForm();
    }
    return ({
        handleSuccessUpdate,
        handleSuccessRemoveVisit,
    })
}

export default useHandleSuccess;