import React from "react";
import FormTwoTextButton from "../../../../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton";
import useLogicRemoveAbonementSettingForm from "./logic/useLogicRemoveAbonementSettingForm";

/** Форма удаления настроек абонементов */
const RemoveAbonementSettingForm = ({ abonementSetting, closeForm, handleSuccessRemove }) => {

    const {
        isLoading,
        validationMessage, clearValidationMessage,
        removeAbonementSetting,
    } = useLogicRemoveAbonementSettingForm({ abonementSetting, handleSuccessRemove })

    return (
        <FormTwoTextButton
            title={'Удалить'}
            nameButtonOne={'Да'}
            nameButtonTwo={'Нет'}
            clickButtonOne={removeAbonementSetting}
            clickButtonTwo={closeForm}
            isLoading={isLoading}
            textForFill={validationMessage}
            cleanTextForFill={clearValidationMessage}
        />
    )
}

export default RemoveAbonementSettingForm;