import React from "react";
import { useStudio } from "../../../../context/studioContext";

/** Элемент верткального списка тренировок в распиании */
function TimeTableListElement({
    disabledButton = false,
    training,
    isTypeSpace = true,
}) {
    const { objStyles } = useStudio();
    let date = new Date(training.dateOn);
    let dateTo = new Date(training.dateTo);

    let dateTrain = new Intl.DateTimeFormat('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' }).format(date);
    let formattedDate = dateTrain.replace(/\b(\d)\b/g, '0$1'); // Добавление ведущего нуля, если день месяца однозначный

    let timeTo = dateTo.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
    let time = date.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });

    const trainer = `${training.trainer.name} ${training.trainer.surname}`
    const typeTraining = training.typeOfTraining.type;

    return (
        <>
            <div className="time-table-list-element" >
                <p className={`time-table-list-element__text ${objStyles.colorText}`}>{formattedDate}</p>
                <p className={`time-table-list-element__text time-table-list-element_dateTrain ${objStyles.colorText}`}>{training.name ? training.name : ''} ({typeTraining})</p>
            </div>
            <div className="time-table-list-element" >
                <p className={`time-table-list-element__text ${objStyles.colorText}`}>{time} - {timeTo}</p>
                <p className={`time-table-list-element__text ${objStyles.colorText}`}>{trainer}</p>
            </div>
            <div className="time-table-list-element" >
                {isTypeSpace ?  // показывать ли тип зала
                    <p className={`time-table-list-element__text ${objStyles.colorText}`}>{training.space.name} зал</p>
                    : <></>}
                <p className={`time-table-list-element__text ${objStyles.colorText}`}>Вместимость {training.capacity}</p>
                {/* <p className={`time-table-list-element__text `}>{typeTraining}</p> */}
            </div>
        </>
    )
}

export default TimeTableListElement;