import React from "react";
import FormTwoTextButton from "../../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton";
import useLogicFormRemoveNonMembershipVisit from "./logic/useLogicFormRemoveNonMembershipVisit";

const FormRemoveNonMembershipVisit = ({
    visit,
    closeForm,
    handleSucces,
}) => {

    const {
        isLoading,
        validateMessage, setValidMessage,
        removeNonMemberShipVisit,
    } = useLogicFormRemoveNonMembershipVisit({
        visit,
        closeForm,
        handleSucces,
    });

    return (
        <FormTwoTextButton
            title={'Удалить запись'}
            nameButtonOne={'Да'}
            nameButtonTwo={'Нет'}
            clickButtonOne={removeNonMemberShipVisit}
            clickButtonTwo={closeForm}
            isLoading={isLoading}
            textForFill={validateMessage}
            cleanTextForFill={() => setValidMessage()}
        />
    )
}

export default FormRemoveNonMembershipVisit;