import React from 'react';
import TrainerInfo from './components/TainerInfo';
import { useNavigate } from 'react-router-dom';
import useLogicTrainersList from './logic/useLogicTrainersList';
import Loader from '../../../../../commonComponents/loaders/Preloader/Preloader';
import ButtonWithBorder from '../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder';
import FormAddTrainer from './components/forms/FormAddTrainer/FormAddTrainer';
import SearchForm from '../../../../../commonComponents/search/SearchForm/SearchForm';
import { useStudio } from '../../../../../context/studioContext';

/** Компонент работы со списком клиентов */
function TrainersList() {
    const { objStyles } = useStudio();
    const navigate = useNavigate();
    function navigateSelctedTrainerPage(e, trainer) {
        e.preventDefault();
        navigate('/trainer', { state: trainer });
    }

    const {
        list,
        openedForm,
        setOpenedForm,
        addTrainer,
        isLoadingFill,
        handleSubmOnSearch,
    } = useLogicTrainersList();

    return (
        <div className="list">
            <SearchForm handleSubmOnSearch={handleSubmOnSearch} />
            <div className='list__button-container'>
                <ButtonWithBorder
                    text={'Добавить +'}
                    onClick={() => setOpenedForm('FormAddTrainer')}
                />
            </div>
            {isLoadingFill ?
                <div className='margin-top__30px'>
                    <Loader />
                </div>
                :
                // {/* СПИСОК */ }
                < div className="list margin-top__30px">
                    {list !== undefined ?
                        list.map(trainer => {
                            return (
                                <button
                                    className={`list__element ${objStyles.colorText}`}
                                    id={trainer._id}
                                    key={trainer._id}
                                    onClick={(e) => { navigateSelctedTrainerPage(e, trainer) }}
                                >
                                    <TrainerInfo trainer={trainer} />
                                </button>)
                        })
                        : <></>
                    }
                </div>}
            <FormAddTrainer
                isOpen={openedForm === 'FormAddTrainer'}
                isClose={() => { setOpenedForm('') }}
                textButtonSubmit={'Добавить'} // текст кнопки сабмита
                handleSuccessAdd={addTrainer}
            />
        </div >
    )
}



export default TrainersList;
