import ModalWithChildren from "../../../../../commonComponents/modal/ModalWithChildren";
import FormChoiceAbonementCRUD from "./components/FormChoiceAbonementCRUD/FormChoiceAbonementCRUD";
import FormCreateOrUpdateAbonement from "./components/FormCreateOrUpdateAbonement/FormCreateOrUpdateAbonement";
import FormRemoveAbonement from "./components/FormRemoveAbonement/FormRemoveAbonement";
import useLogicAbonement from "./logic/useLogicAbonement";

/** Форма работы с абонементом ( создать или редактировать или удалить) */
function FormAbonement({
    type,
    isUpdate,
    isOpen,
    isClose,
    handleSuccessSubmit, // действия при сабмите
    abonement,
    abonementSettigsInputOptions,
    client,
}) {

    const {
        openedForm,
        setOpenedForm,
        handleCreate,
        clickCloseForm,
        handleAbonementRemove,
    } = useLogicAbonement({
        abonement,
        client,
        handleSuccessSubmit, //
        isClose,
    });

    return (
        <ModalWithChildren
            // onSubmit={onSubmit} // сабмит
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={clickCloseForm} // клик на кнопку для сворачивания
            children={
                <>
                    {openedForm === 'FormChoiceAbonementCRUD' &&
                        <FormChoiceAbonementCRUD
                            abonement={abonement}
                            openCreateForm={() => setOpenedForm('FormCreateOrUpdateAbonement')}
                            openUpdateForm={() => setOpenedForm('FormCreateOrUpdateAbonement')}
                            openDeleteForm={() => setOpenedForm('FormDeleteAbonement')}
                        />
                    }
                    {openedForm === 'FormCreateOrUpdateAbonement' &&
                        <FormCreateOrUpdateAbonement
                            abonement={abonement}
                            client={client}
                            handleCreate={handleCreate}
                            type={type}
                            abonementSettigsInputOptions ={abonementSettigsInputOptions}
                        />}
                    {openedForm === 'FormDeleteAbonement' && abonement &&
                        <FormRemoveAbonement
                            abonementId={abonement._id}
                            handleRemove={handleAbonementRemove}
                            closeForm={clickCloseForm}
                            />
                    }
                </>
            }
        />
    )
}

export default FormAbonement;