import React from 'react';
import ModalWithChildren from '../../../../../commonComponents/modal/ModalWithChildren.js'
import FormTwoTextButton from '../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton.js'
import useLogicModalUpdateOrRemove from './logic/useLogicModalUpdateOrRemove'

function ModalUpdateOrRemove({ isOpen, closeForm, selectedTrainingSetting, handleClickOpenFormUpdate, handleSuccessRemove }) {

    const {
        openedForm, setOpenedForm,
        isLoading,
        validationText, setValidationText,
        removeTrainingSettings,
    } = useLogicModalUpdateOrRemove({ handleClickOpenFormUpdate, closeForm, selectedTrainingSetting, handleSuccessRemove });

    return (
        <ModalWithChildren
            isOpen={isOpen}
            isClose={closeForm}
            children={
                <>
                    {openedForm === 'formChoice' &&
                        <FormTwoTextButton
                            title={selectedTrainingSetting?.type}
                            nameButtonOne={'Редактировать'}
                            nameButtonTwo={'Удалить'}
                            clickButtonOne={handleClickOpenFormUpdate}
                            clickButtonTwo={() => setOpenedForm('formRemove')}
                        />}
                    {openedForm === 'formRemove' &&
                        <FormTwoTextButton
                            title={selectedTrainingSetting?.type}
                            nameButtonOne={'Удалить'}
                            nameButtonTwo={'Нет'}
                            clickButtonOne={removeTrainingSettings}
                            clickButtonTwo={() => setOpenedForm('formChoice')}
                            isLoading={isLoading}
                            textForFill={validationText}
                            cleanTextForFill={() => setValidationText()}
                        />
                    }
                </>
            }
        />
    )
}

export default ModalUpdateOrRemove;