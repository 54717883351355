import { useState } from "react";

function useFormAddTrainer({ isClose }) {

    const [openedForm, setOpenedForm] = useState('FormChoice');

    function closeForm() {
        setOpenedForm('FormChoice');
        isClose();
    }

    return ({
        openedForm, setOpenedForm,
        closeForm
    })
}

export default useFormAddTrainer;