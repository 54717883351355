import ClientInfo from "../../../../../commonComponents/pages/ClientInfo/ClientInfo";
import ListWithOnClickElement from "../../../../../commonComponents/pages/ListWithOnClickElement/ListWithOnClickElement";
import SearchForm from "../../../../../commonComponents/search/SearchForm/SearchForm";
import LoaderAbovePage from "../../../../../commonComponents/loaders/LoaderAbovePage/LoaderAbovePage"
import useLogicFormAddByNameOrPhone from "./logic/useLogicFormAddByNameOrPhone";
import FormTwoTextButton from "../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton";

/**Форма добаления клиентов на тренировку по имени или фамилии*/
function FormAddByNameOrPhone({ training, handleSuccessAddVisit }) {

    const {
        clients,
        selectedClient,
        search,
        loading,
        openedForm, setOpenedForm,
        validMessage, setValidMessage,
        сlickOnClient,
        addVisit,
        isMembership,
    } = useLogicFormAddByNameOrPhone({ training, handleSuccessAddVisit });

    return (
        <div>
            {openedForm !== "FormTwoTextButton" &&
                <div className="client-list-on-training-page__search-form">
                    <SearchForm handleSubmOnSearch={search} />
                </div>
            }
            {openedForm === "FormTwoTextButton" &&
                <FormTwoTextButton
                    title={selectedClient && isMembership ? selectedClient.name + ' ' + selectedClient.surname : 'Нет действующего абонемента, добавить на разовую запись?'}
                    nameButtonOne={'Добавить'}
                    nameButtonTwo={'Нет'}
                    clickButtonOne={addVisit}
                    clickButtonTwo={() => setOpenedForm('')}
                    isLoading={false}
                    textForFill={validMessage}
                    cleanTextForFill={() => setValidMessage('')}
                />}
            {openedForm !== "FormTwoTextButton" &&
                <ListWithOnClickElement
                    array={clients}
                    handleClickOnElement={сlickOnClient}
                    children={(client) =>
                        <ClientInfo
                            client={client}
                            fullInfo={false}
                        />
                    }
                />
            }
            <LoaderAbovePage isOpen={loading} />
        </div>
    )
}

export default FormAddByNameOrPhone;