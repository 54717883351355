import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUserContext } from "../../../context/userContext";
import apiTrainer from "../../../api/apiTrainer";
import { useStudio } from "../../../context/studioContext";

function useLogicTrainerPage() {
    const { userData } = useUserContext();
    const { studio } = useStudio();
    const location = useLocation();
    const [trainer, setTrainer] = useState();
    const [selectedDate, setSelectedDate] = useState();
    const [rangeStatisticDate, setRangeStatisticDate] = useState({ dateOn: new Date(), dateTo: new Date() });
    const [openedForm, setOpenedForm] = useState();
    const [isLoading, setIsLoading] = useState(false);

    // Управление формами
    function openFormTrainer() {
        if (userData.role === 'superAdmin') {
            setOpenedForm('formTrainer')
        }
    }

    function openFormUpdateTranerPaymentSetting() {
        if (userData.role === 'superAdmin') {
            setOpenedForm('FormUpdateTranerPaymentSetting')
        }
    }

    function closeModal() {
        setOpenedForm('')
    }

    function handleSuccessUpdate() {
        fillTrainer()
        setOpenedForm()
    }

    async function fillTrainer() {
        try {
            setIsLoading(true);
            let trainerId = '';
            if (userData.role.toLowerCase().includes('admin')) {
                if (location.state) {
                    trainerId = location.state._id;
                }
            } else if (userData.role.toLowerCase() === 'trainer') {
                trainerId = userData._id;
            }

            const trainer = await apiTrainer.getTrainerById(studio._id, trainerId);
            setTrainer(trainer);
            setIsLoading(false);
        }
        catch (err) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fillTrainer()
    }, [location, userData])

    useEffect(() => {
        if (selectedDate) {
            // Преобразуем startDate в объект Date, если он является строкой
            const start = new Date(selectedDate);
            // Получаем год и месяц из начальной даты
            const year = start.getFullYear();
            const month = start.getMonth();
            // Создаем новую дату, представляющую первый день следующего месяца
            const firstDayNextMonth = new Date(year, month + 1, 1);
            // Вычитаем один день, чтобы получить последний день текущего месяца
            const dateTo = new Date(firstDayNextMonth - 1);
            // Устанавливаем время на 23:59:59.999, чтобы получить точное окончание месяца
            dateTo.setHours(23, 59, 59, 999);

            const obj = {
                dateOn: selectedDate,
                dateTo,
            }
            setRangeStatisticDate(obj)
        }

    }, [selectedDate])

    return ({
        userData,
        trainer,
        handleSuccessUpdate,
        selectedDate, setSelectedDate,
        rangeStatisticDate,
        openedForm, setOpenedForm,
        isLoading,
        openFormTrainer,
        openFormUpdateTranerPaymentSetting,
        closeModal,
    })
}

export default useLogicTrainerPage;