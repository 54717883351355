import { useState } from "react";
import apiTranerPaymentSetting from "../../../../../../../api/apiTranerPaymentSetting";

function useLogicFormUpdateTranerPaymentSetting({ handleSuccessUpdate }) {

    const [paymentSetting, setPaymentSetting] = useState();
    const [isValidForm, setIsValidForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validFormText, setValidtextForm] = useState();

    async function update() {
        try {
            setLoading(true);
            await apiTranerPaymentSetting.update(paymentSetting);
            handleSuccessUpdate();
            setLoading(false);
        }
        catch (err) {
            setValidtextForm(err.message);
            setLoading(false);
        }
    }

    function fillPaymentSetting(data) {
        setPaymentSetting(data);
    }

    function clearTextValidation(){
        setValidtextForm();
    }

    return ({
        update,
        isValidForm, setIsValidForm,
        loading,
        validFormText, setValidtextForm,
        fillPaymentSetting,
        clearTextValidation,
    })
}

export default useLogicFormUpdateTranerPaymentSetting;