import ButtonWithBorder from "../../buttons/ButtonWithBorder/ButtonWithBorder";

function FormRemove({
    onRemove,
    closeForm,
    isLoading,
    textForFill, // текст для заполнения формы им 
    cleanTextForFill, // очистить текст для заолнения
}) {

    return (
        <div className="form-remove">
            {textForFill ?
                <div>
                    <p>{textForFill}</p>
                    <ButtonWithBorder
                        text={'Назад'}
                        onClick={cleanTextForFill}
                    />
                </div>
                :
                <>
                    <p className="form-remove__title">Удалить</p>
                    <div className="form-remove__container-buttons">
                        <ButtonWithBorder text={'Да'} onClick={onRemove} isLoading={isLoading} />
                        <ButtonWithBorder text={'Нет'} onClick={closeForm} />
                    </div>
                </>
            }
        </div>

    )
}

export default FormRemove;