import React, { useState } from "react";
import FormRemove from "../../../../../../../commonComponents/forms/FormRemove/FormRemove";
import ButtonWithBorder from "../../../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import apiAbonement from "../../../../../../../api/apiAbonement";

function FormRemoveAbonement({
    abonementId,
    handleRemove,
    closeForm,
}) {

    const [isLoading, setIsLoading] = useState(false);
    const [textForFill, setTextForFill] = useState();

    async function remove() {
        try {
            setIsLoading(true);
            await apiAbonement.removeAbonement(abonementId)
            handleRemove()
            closeForm()
            setIsLoading(false);
        }
        catch (err) {
            setIsLoading(false);
            setTextForFill(err.message);
            console.log(err);
        }
    }

    return (
        <>
            {textForFill ?
                <div className="formadd__text-fill-container">
                    <p className="formadd__text-fill">{textForFill}</p>
                    <ButtonWithBorder
                        text={'Назад'}
                        onClick={() => { setTextForFill() }}
                    />
                </div>
                :
                <FormRemove
                    onRemove={remove}
                    isLoading={isLoading}
                    closeForm={closeForm}
                />
            }
        </>
    )
}

export default FormRemoveAbonement;