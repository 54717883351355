import React, { createContext, useContext, useEffect, useState, } from 'react';
import apiEmployee from '../api/apiEmployee';
import { getCompanyBranchesAsyncLocalStorage, refreshCompanyBranchesAsyncLocalStorage, saveCompanyBranchesAsyncLocalStorage } from '../utils/localStorage/studioChoice';

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
    const [userData, setUserData] = useState(undefined);
    const [authLoading, setAuthLoading] = useState(false);
    const [handleChange, setHandleChange] = useState(false);

    async function authUser() {
        try {
            setAuthLoading(true);
            const user = await apiEmployee.getSelfEmployee();
            if (user) {
                setUserData(user);
                refreshCompanyBranchesAsyncLocalStorage(user.companyBranches)
            }
            setAuthLoading(false);
        }
        catch (err) {
            setAuthLoading(false);
            console.log(err);
        }
    }

    /** Сравнить актуальные доступы к филиалам и актуализировать при необходимости */
    async function compare(data) {
        const currentBrunches = await getCompanyBranchesAsyncLocalStorage();
        const resBranches = data.companyBranches;
        if (JSON.stringify(currentBrunches) !== JSON.stringify(resBranches)) {
            saveCompanyBranchesAsyncLocalStorage(data.companyBranches)
        }
    }

    function refreshUserAuth() {
        setHandleChange(!handleChange)
    }

    useEffect(() => {
        authUser();
    }, [handleChange])

    return (
        <UserContext.Provider value={{
            userData,
            authLoading,
            refreshUserAuth,
        }}>
            {children}
        </UserContext.Provider>
    )

}


export const useUserContext = () => {
    return useContext(UserContext);
};