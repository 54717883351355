import { useEffect, useState } from "react";
import apiNonMemberShipVisit from "../../../api/apiNonMemberShipVisit";
import { useStudio } from "../../../context/studioContext";

function useLogicNonMembershipVisit() {
    const { studio } = useStudio();
    const [nonMemebershipVisits, setNonMemebershipVisits] = useState([]);
    const [loading, setLoading] = useState('complete');
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [selectedVisit, setSelectedVisit] = useState();

    async function getVisits() {
        try {
            setLoading('fill');
            const res = await apiNonMemberShipVisit.getByCompanyBranchId(studio._id)
            setNonMemebershipVisits(res.nonMembershipVisits)
            setLoading('complete');
        }
        catch (err) {
            setLoading('complete');
            console.log(err);
        }
    }

    function clickOnVisit(visit) {
        setSelectedVisit(visit);
        setIsOpenForm(true);
    }

    function handleSuccessConfirm() {
        const updatedVisits = nonMemebershipVisits.filter(visit => visit._id !== selectedVisit._id);
        setNonMemebershipVisits(updatedVisits);
        setSelectedVisit();
    }

    function handleRemove() {
        const updatedVisits = nonMemebershipVisits.filter(visit => visit._id !== selectedVisit._id);
        setNonMemebershipVisits(updatedVisits);
        setSelectedVisit();
    }

    useEffect(() => {
        getVisits()
    }, [])

    return ({
        selectedVisit,
        loading,
        nonMemebershipVisits,
        isOpenForm, setIsOpenForm,
        clickOnVisit,
        handleSuccessConfirm,
        handleRemove,
    })
}

export default useLogicNonMembershipVisit; 