import React from "react";

function CircleIndicator({ color }) {

    const backgroundColor = {
        green: 'circle-indicator_green',
        yellow: 'circle-indicator_yellow',
        red: 'circle-indicator_red'
    };

    return (
        <div className={`circle-indicator ${backgroundColor[color]}`}></div>
    )
}
export default CircleIndicator;