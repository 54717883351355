import React from "react";

/** Информация о тренере */
function TrainerInfo({ trainer, onClick }) {

    return (
        <div className="trainer-info" onClick={onClick}>
            <div className="trainer-info__container-name">
                <p className="trainer-info__text trainer-info__text_name">{trainer && trainer.name}</p>
                <p className="trainer-info__text">{trainer && trainer.surname}</p>
            </div>
            {trainer && trainer.contactData && trainer.contactData.email &&
                <p className="trainer-info__text">{trainer.contactData.email}</p>
            }
            {trainer && trainer.contactData && trainer.contactData.phoneNumber &&
                <p className="trainer-info__text">{trainer.contactData.phoneNumber}</p>
            }
        </div>
    )
}

export default TrainerInfo;