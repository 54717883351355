/**Функция для сортировки массива по полю "surname" по первой букве в алфавитном порядке (кириллица)  */
function sortBySurname(people) {
    // Функция сравнения для сортировки по полю "surname"
    function compareBySurname(a, b) {
        // Сравниваем строки по первой букве
        return a.surname.localeCompare(b.surname, 'ru', { sensitivity: 'base' });
    }
    // Возвращаем отсортированный массив
    return people.slice().sort(compareBySurname);
}

export {
    sortBySurname
}