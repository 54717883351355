import { useEffect, useState } from "react";
import apiTrainingSettings from "../../../api/apiTrainingSettings";
import { useStudio } from "../../../context/studioContext";

function useLogicTrainingSettingPage() {
    const { studio } = useStudio();
    const [trainingSettings, setTrainingSettings] = useState(undefined);
    const [notActualTrainingSettings, setNotActualTrainingSettings] = useState(undefined);
    const [selectedTrainingSetting, setSelectedrainingSetting] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [openedForm, setOpenedForm] = useState('nameForm');

    function handleSuccessCreate(data) {
        setTrainingSettings(prev => [...prev, data])
        closeForm()
    }

    function handleSuccessUpdate() {
        getTrainingSettings()
        closeForm()
    }

    function handleSuccessRemove() {
        const filtredArray = trainingSettings.filter((item) => item._id !== selectedTrainingSetting._id);
        setTrainingSettings(filtredArray);
        closeForm()
    }

    function handleClickrainingSetting(element) {
        setSelectedrainingSetting(element)
        setOpenedForm('FormUpdateOrRemove')
    }

    function closeForm() {
        setOpenedForm()
        setSelectedrainingSetting()
    }

    //ПОЛУЧИТЬ НАСТРОЙКИ ТРЕНИРОВОК
    async function getTrainingSettings() {
        try {
            setIsLoading(true)
            const data = await apiTrainingSettings.getTrainingSettingsByCompanyBranchId(studio._id)
            const sortedArray = data.trainingSettings.sort((a, b) => {
                if (a.type < b.type) {
                    return -1;
                }
                if (a.type > b.type) {
                    return 1;
                }
                return 0;
            })
            let actual = []
            let notActual = []
            sortedArray.forEach((item) => {
                if (item.isActual) {
                    actual.push(item)
                } else {
                    notActual.push(item)
                }
            })
            setTrainingSettings(actual);
            setNotActualTrainingSettings(notActual)
            setIsLoading(false)
        }
        catch (err) {
            setIsLoading(false)
            throw new Error(err);
        }
    }

    useEffect(() => {
        getTrainingSettings()
    }, [])

    return ({
        trainingSettings,
        notActualTrainingSettings,
        isLoading,
        openedForm, setOpenedForm,
        selectedTrainingSetting,
        handleSuccessCreate,
        handleSuccessUpdate,
        handleSuccessRemove,
        handleClickrainingSetting,
        closeForm,
    })
}

export default useLogicTrainingSettingPage;