import React from "react";
import Preloader from "../../../../commonComponents/loaders/Preloader/Preloader";
import useLogicTrainerStatistics from "./logic/useLogicTrainerStatistics";
import TrainerPayment from "./components/TrainerPayment";

/** Компонент для отображения статистики по тренеру ( количество проведенных тренировок и посещений) */
function TrainerStatistics({ dateOn, dateTo, trainerId }) {

    const {
        trainings,
        trainingSummary,
        isLoading,
        validateMessage,
    } = useLogicTrainerStatistics({ dateOn, dateTo, trainerId });

    return (
        <div className="trainer-statistics">
            <p className="trainer-statistics__title">Посещения</p>
            {!isLoading ?
                <>
                    {validateMessage &&
                        <p>{validateMessage}</p>}
                    <TrainerPayment trainingSummary={trainingSummary} />

                </>
                :
                <Preloader />}
        </div>
    )
}

export default TrainerStatistics;