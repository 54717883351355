import React from 'react';
import ClientInfo from '../../../../../commonComponents/pages/ClientInfo/ClientInfo'
import { useNavigate } from 'react-router-dom';
import useLogicClientsList from './logic/useLogicClientsList';
import Loader from '../../../../../commonComponents/loaders/Preloader/Preloader';
import ButtonWithBorder from '../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder';
import FormAddClient from './components/forms/FormAddClient/FormAddClient';
import SearchForm from '../../../../../commonComponents/search/SearchForm/SearchForm';
import { useStudio } from '../../../../../context/studioContext';

/** Компонент работы со списком клиентов */
function ClientsList() {
    const { objStyles } = useStudio();
    const navigate = useNavigate();
    function navigateSelctedClientPage(e, client) {
        e.preventDefault();
        navigate('/client', { state: client });
    }

    const {
        list,
        openedForm,
        setOpenedForm,
        addClient,
        isLoadingFill,
        handleSubmOnSearch,
    } = useLogicClientsList();

    return (
        <div className="list">
            <SearchForm handleSubmOnSearch={handleSubmOnSearch} />
            <div className='list__button-container'>
                <ButtonWithBorder
                    text={'Добавить +'}
                    onClick={() => setOpenedForm('FormAddClient')}
                />
            </div>
            {isLoadingFill ?
                <div className='margin-top__30px'>
                    <Loader />
                </div>
                :
                // {/* СПИСОК КЛИЕНТОВ */ }
                < div className="list margin-top__30px">
                    {list !== undefined ?
                        list.map(client => {
                            return (
                                <button
                                    className={`list__element ${objStyles.colorText}`}
                                    id={client._id}
                                    key={client._id}
                                    onClick={(e) => { navigateSelctedClientPage(e, client) }} // клик на клиента в списке
                                >
                                    <ClientInfo client={client} />
                                </button>)
                        })
                        : <></>
                    }
                </div>}
            <FormAddClient
                isOpen={openedForm === 'FormAddClient'}
                isClose={() => { setOpenedForm('') }}
                textButtonSubmit={'Добавить'} // текст кнопки сабмита
                handleSuccessAdd={addClient}
            />
        </div >
    )
}



export default ClientsList;
