import React from "react";
import Preloader from "../Preloader/Preloader";

function LoaderAbovePage({ isOpen }) {
    return (
        <div className={`formadd  formadd_overlay ${isOpen ? "formadd_show" : ""}`}>
            <Preloader />
        </div>
    )
}

export default LoaderAbovePage;