import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function useClient({ }) {
    const location = useLocation();
    const [client, setClient] = useState();

    useEffect(() => {
        if (location.state) {
            setClient(location.state);
        }
    }, [location])
    
    return ({
        client, setClient
    })
}

export default useClient;